//  Dependencies
// ===========================================================

import { useState } from "react"
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'


//  Modules
// ===========================================================

import { Spinner } from '#components/Elements'
import { updateOrder, updateStripe } from '#stores/components/bookSlice'
import { BtnWhiteFilled, BtnWhite } from '#components/Buttons/index.jsx'


//  Functions
// ===========================================================

import useHttpsCallable from '#hooks/useHttpsCallable'
import fnRideDuration from '#functions/fnRideDuration'



//  Components (local)
// ===========================================================

function Field({ hide, label, children }) {

  // Hide
  if ( hide )
    return null

  // Show
  return (
    <div className="w-full flex flex-col font-dm-light">
      <label className="text-base md:text-lg text-grey"> {label} </label>
      <p className="text-lg md:text-xl text-black"> {children} </p>
    </div>
  )
}

function CheckBox({ hide, label, children }) {

  // Hide
  if ( hide )
    return null

  // Show
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="w-4 h-4 border-[1px] p-[1px] border-black">
        {(children === true) &&
          <div className="w-full h-full bg-black" />        
        }
      </div>
      <label className="font-dm-light text-lg md:text-xl text-black"> {label} </label>            
    </div>
  )
}


//  Content
// ===========================================================

function UserInformation({ t, order }) {
  
  // Constants 
  const cars = t(`cars`, { returnObjects: true });

  // Duration
  const duration = fnRideDuration(order);


  return (
    <>
      <Field label={t('order.from')}>
        {order?.from || "--"} 
      </Field>
      <Field label={t('order.to')} hide={!order?.to}>
        {order?.to} 
      </Field>
      <CheckBox label={t('order.roundTrip')} hide={!order?.roundTrip}>
        {order?.roundTrip === 'true'} 
      </CheckBox>
      <Field label={t('order.date')}>
        {order?.date || "--"} 
      </Field>
      <Field label={t('order.time')}>
        {(order?.timeStartHour && order?.timeStartMinute)
          ? `${order?.timeStartHour}h${order?.timeStartMinute}`
          : "--"
        }
        {(order?.timeEndHour && order?.timeEndMinute)
          ? ` ${t('global.to')} ${order?.timeEndHour}h${order?.timeEndMinute}`
          : null
        }
        {(order?.timeEndHour && order?.timeEndMinute)
          ? ` (${duration})`
          : null
        }
      </Field>
      <Field label={t('order.vehicle')}>
        {cars.find(e => e.id === order.vehicle).value || "--"}
      </Field>
      <Field label={t('order.cityTour')} hide={!order?.cityTour}>
        {order?.cityTour}
      </Field>
    </>
  )
}

function TripInformation({ t, order }) {
  return (
    <>
      <Field label={t('order.email')}>
        {order?.email || '--'} 
      </Field>
      <Field label={t('order.phone')}>
        {order?.phone || '--'} 
      </Field>
      <Field label={t('order.passengers')}>
        {`${order?.adults || '--'} ${t('global.and')} ${order?.children || '--'} `}
      </Field>
      <Field label={t('order.luggages')}>
        {`${order?.luggages || '--'} ${t('summary.luggageIncluded')}`}
        {order?.luggagesMore ? ` ${t('global.and')} ${order?.luggagesMore || '--'} ${t('summary.luggageExtra')}` : ""}
      </Field>
      <Field label={t('summary.options')}>
        {`${order?.childSeats || '--'} ${t('summary.childSeats')}
          ${t('global.and')}
          ${order?.boosterSeats || '--'} ${t('summary.boosterSeats')}
        `}
      </Field>
      <Field label={t('summary.welcomePanel')}>
        {order?.welcomePanel || '--'} 
      </Field>
      <Field label={t('summary.request')}>
        {order?.request || '--'} 
      </Field>
    </>
  )
}


//  Page
// ===========================================================

function Page({ t, setSubpage }) {

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector(state => state.book.order, () => true);
  const price = useSelector(state => state.book.price, () => true);

  // Variables
  const [errorPay, setErrorPay] = useState(0);

  // Cloud functions
  const sendMail = useHttpsCallable("sendOrderMail");
  const createPayment = useHttpsCallable("createPaymentIntent");

  // Confirm
  const pay = () => {
    createPayment
      .call(order)
      .then(res => {
        if (res?.id) {
          dispatch(updateStripe(res.id));
          setTimeout(() => dispatch(updateOrder({})), 1000);
          navigate(`/checkout?order=${res.id.slice(-6)}`);
        } else {
          setErrorPay(prevState => (prevState + 1));
          toast.error(t('summary.pay_error'));
        }
      });
  }

  // Confirm
  const send = () => {
    sendMail
      .call(order)
      .then(res => {
        if (res?.messageId) {
          setSubpage(prevState => (prevState + 1))
          setTimeout(() => dispatch(updateOrder({})), 1000)
        } else {
          toast.error(t('summary.mail_error'))
        }
      })
  }

  // Edit
  const edit = () => {
    setSubpage(prevState => (prevState - 1))
  }


  // Page
  return (
    <div className="flex flex-col justify-center items-center gap-6 md:gap-12 p-3 md:p-12">
      <h1 className="font-pf-regular text-4xl text-white text-center"> 
        {t('summary.title')}
      </h1>
      <div className="w-full flex lg:max-w-[1300px] flex-col lg:flex-row gap-6 md:gap-12">
        <div className="w-full flex flex-col gap-6 p-6 md:p-12  bg-card rounded-xl">
          <UserInformation t={t} order={order} />
        </div>
        <div className="w-full flex flex-col gap-6 p-6 md:p-12  bg-card rounded-xl">
          <TripInformation t={t} order={order} />
        </div>
      </div>
      <div className="flex flex-col text-white text-center">
        <label className="font-dm-regular text-lg uppercase">
          {t('global.rate')}
        </label>
        <p className="font-dm-light text-xl">
          {(price > 0) 
            ? `${price?.toFixed(2)}€`
            : (price === -1) 
              ? t('global.no_price')
              : "---"
          }
        </p>
      </div>
      <div className="w-full max-w-[300px] flex flex-col gap-3">
        {(!price)
          ? <div className="w-full h-full flex items-center justify-center">
              <Spinner className="w-12" />
            </div>
          : (price > 0) && (errorPay < 2)
              ? <BtnWhiteFilled onClick={() => pay()} disabled={createPayment.loading}>
                  {t('summary.payment')}
                </BtnWhiteFilled>
              : <BtnWhiteFilled onClick={() => send()} disabled={sendMail.loading}>
                  {t('summary.send')}
                </BtnWhiteFilled>
        }
        <BtnWhite onClick={() => edit()}>
          {t('summary.edit')}
        </BtnWhite>
      </div>
    </div>
  )
}


export default withTranslation()(Page)