//  Modules (layouts, assets, stores, components)
// ===========================================================

import i18n from '#services/i18n'
import { toast } from 'react-toastify'


//  Function
// ===========================================================

export default function fnMinStartTime(order) {

  // Requirements
  if ( !order?.date || !order?.timeStartHour || !order?.timeStartMinute ) {
    return false
  }

  // Set user date
  const dateUser = new Date(order.date)
  dateUser.setHours(order.timeStartHour)
  dateUser.setMinutes(order.timeStartMinute)

  // Get timestamp
  const timestamp = dateUser.getTime()
  const nowMore2h = (Date.now() + 2*60*60*1000)

  // Minimum 2 hours
  if (nowMore2h >= timestamp) {
    const time = new Date(nowMore2h)
    const hour = time.getHours();
    const min = (time.getMinutes() > 9) ? time.getMinutes() : `0${time.getMinutes()}`
    toast.error(i18n.t('toast.error.startTime', { time: `${hour}h${min}`} ))
    return false
  }

  return true
}
