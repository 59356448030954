//  Dependencies
// ===========================================================

import { useState, useEffect } from "react"
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'


//  Modules
// ===========================================================

import { updateOrder } from '#stores/components/bookSlice'
import { CarVehicles } from '#components/Carousels'


//  Functions
// ===========================================================

import useResponsiveDesign from '#hooks/useResponsiveDesign';


//  Assets
// ===========================================================

import { BiSolidChevronLeft, BiSolidChevronRight } from 'react-icons/bi'
import { BsClock } from 'react-icons/bs'
import { FaBottleWater } from 'react-icons/fa6'
import { FaWifi } from 'react-icons/fa'
import { MdStars } from 'react-icons/md'


//  Constants
// ===========================================================

import VEHICLES from '#constants/vehicles'


//  Functions (local)
// ===========================================================

function Price({}) {

  // Hooks
  const { t } = useTranslation();
  const price = useSelector(state => state.book.price);

  return (
    <p className="font-dm-thin text-lg italic">
      {(price > 0) 
        ? `${price?.toFixed(2)}€`
        : (price === -1) 
          ? t('global.no_price')
          : "---"
      }
    </p>
  )
}


//  Components (local)
// ===========================================================

function Title({ t }) {

  // Constants
  const title = t('vehicles.title');

  // Page
  return (
    <div className="px-3 max-w-[400px]">
      <h1 className="font-dm-thin text-sm text-center uppercase sm:text-base">
        {title}
      </h1>
    </div>
  )
}

function Equipments({ t }) {

  // Constants
  const data = t('vehicles.equipments', { returnObjects: true });

  // Hooks
  const [indexShow, setIndexShow] = useState(0);

  // Icons
  const equipementIcons = [
    {
      id: "wifi",
      icon: <FaWifi />,
    },
    {
      id: "water",
      icon: <FaBottleWater />,
    },
    {
      id: "clock",
      icon: <BsClock />,
    }
  ];

  // Update auto scroll
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndexShow((prevState) => {
        const newState = prevState + 1
        if (newState < equipementIcons.length)
          return newState
        else
          return 0
      })
    }, 5000)
    return () => clearTimeout(timeout)
  }, [indexShow]);


  // Page
  return (
    <div className="w-full flex flex-col items-center px-3">
      <div className="
        w-full max-w-[1000px] bg-[#E0DDDA] rounded-2xl 
        flex flex-row justify-evenly items-start gap-6 p-3 sm:p-6
        text-black
      ">
        {equipementIcons.map((item, index) => (
          <div key={item.id} className="w-full flex flex-col items-center gap-3" onClick={() => setIndexShow(index)}>
            <div className={`text-[40px] sm:text-[50px] ${indexShow === index ? "" : "opacity-40 sm:opacity-100"}`}>
              {item.icon}
            </div>
            <p className="font-dm-thin text-base text-center hidden sm:block">
              {data[item.id]}
            </p>
          </div>
        ))}
      </div>
      <div className="w-full pt-3 px-6">
        <p className="font-dm-thin text-base text-center text-white block sm:hidden">
          {data[equipementIcons[indexShow].id]}
        </p>
      </div>
    </div>
  )
}

function CarsMobile({ t, setCar, cars, carIndex, updateIndex }) {
  // Page
  return (
    <>

      <div className="relative w-full flex justify-center items-center border-y-[1px] border-y-[#C9C6C3] overflow-hidden">
        <div className="absolute z-[10] left-0 group p-6" onClick={() => updateIndex(-1)}>
          <BiSolidChevronLeft className="text-[50px] text-[#C9C6C366]" />
        </div>
        <div className="w-full flex justify-center items-center">
          <CarVehicles
            index={carIndex} 
            data={(cars || []).map((item, index) => (
              <div 
                key={`CARS-INFO-${index}`} 
                className="w-full flex flex-col justify-center items-center p-3"
              >
                <p className="font-dm-regular text-xl uppercase">
                  {item.name} 
                </p>
                <p className="font-dm-thin text-lg"> 
                  {t(`vehicles.luggage`, { nbr: VEHICLES[item.id].luggage })} - {t(`vehicles.people`, { nbr: VEHICLES[item.id].seat })}
                </p>
                <Price />
              </div>
            ))}
          />
        </div>
        <div className="absolute z-[10] right-0 group p-6" onClick={() => updateIndex(+1)}>
          <BiSolidChevronRight className="text-[50px] text-[#C9C6C366]" />
        </div>
      </div>

      <div className="w-full max-w-[800px] h-full">
        <CarVehicles
          index={carIndex} 
          data={(cars || []).map((item, index) => (
            <div
              key={`CARS-INFO-${index}`} 
              className="w-full flex flex-col-reverse justify-center items-stretch gap-6 px-6"
            >
              <div className="w-full h-full border border-[#C9C6C3] rounded-xl flex flex-col gap-6 p-6">
                {item.left.map(it => (
                    <div key={it.title} className="w-full">
                      <div className="flex items-center gap-3">
                        <MdStars className="text-4xl text-[#C9C6C366]" />
                        <p className="font-dm-regular text-2xl"> {it.title} </p>
                      </div>
                      <p className="font-dm-thin text-lg"> {it.description} </p>
                    </div>
                ))}
              </div>
              <div
                onClick={() => setCar(item)}
                className="w-full h-64 border border-[#C9C6C3] rounded-xl flex flex-col justify-center items-center gap-1 p-1
                  cursor-pointer hover:bg-[#D1D1D1] hover:text-black"
              >
                <div className="relative w-full h-full rounded-xl overflow-hidden">
                  <img 
                    className="relative w-full h-full object-cover lg:absolute"
                    src={`/images/cars/${item.id}.webp`} 
                    alt="car"  
                  />
                </div>
                <p className="font-dm-light text-xl uppercase">
                  {t(`vehicles.select`, { car: item?.fullname || item?.name })}
                </p>
              </div>
            </div>
          ))}
        /> 
      </div>

    </>
  )
}

function CarsComputer({ t, setCar, cars, carIndex, updateIndex }) {

  // Page
  return (
    <>

      <div className="relative w-full justify-evenly items-start flex">
        <div className="absolute z-[0] w-full h-[10px] flex justify-center items-center">
          <div className="w-full h-[1px] bg-[#C9C6C3]" />
        </div>
        {(cars || []).map((item, index) => (
          <div
            key={`CARS-LIST-${index}`} 
            className="w-full flex flex-col justify-center items-center pt-[1px]"
          >
            <div className={`
              w-[10px] z-[1] aspect-square rounded-full border border-[#C9C6C3] 
              ${(carIndex === index) ? "bg-[#C9C6C3]" : "bg-[#000000]"}
            `} />
            <p className="font-dm-bold text-base"> {item.name} </p>
            <p className="font-dm-thin text-base italic"> {t(`vehicles.seat`, { nbr: VEHICLES[item.id].seat })}  </p>
          </div>
        ))}
      </div>

      <div className="relative w-full h-full flex justify-center items-center">
        <div className="absolute z-[10] left-0 group py-6" onClick={() => updateIndex(-1)}>
          <BiSolidChevronLeft className="text-[90px] text-[#C9C6C366] group-hover:text-[#C9C6C3]" />
        </div>
        <CarVehicles
          index={carIndex} 
          data={(cars || []).map((item, index) => (
            <div
              key={`CARS-INFO-${index}`} 
              className="w-full flex flex-row justify-center items-stretch gap-6 px-[100px]"
            >
              <div className="w-full flex flex-col">
                <div className="w-full flex justify-between items-center pb-2">
                  <p className="font-dm-thin text-lg"> {t(`vehicles.luggage`, { nbr: VEHICLES[item.id].luggage })} - {t(`vehicles.people`, { nbr: VEHICLES[item.id].seat })} </p>
                  <Price />    
                </div>
                <div className="w-full h-full border border-[#C9C6C3] rounded-xl flex flex-col gap-6 p-6">
                  {item.left.map(it => (
                      <div key={it.title} className="w-full">
                        <div className="flex items-center gap-3">
                          <MdStars className="text-4xl text-[#C9C6C366]" />
                          <p className="font-dm-regular text-2xl"> {it.title} </p>
                        </div>
                        <p className="font-dm-thin text-lg"> {it.description} </p>
                      </div>
                  ))}
                </div>
              </div>
              <div
                onClick={() => setCar(item)}
                className="w-full h-64 md:h-auto border border-[#C9C6C3] rounded-xl flex flex-col justify-center items-center gap-1 p-1
                  cursor-pointer hover:bg-[#D1D1D1] hover:text-black"
              >
                <div className="relative w-full h-full rounded-xl overflow-hidden">
                  <img 
                    className="relative w-full h-full object-cover lg:absolute"
                    src={`/images/cars/${item.id}.webp`} 
                    alt="car"  
                  />
                </div>
                <p className="font-dm-light text-xl uppercase">
                  {t(`vehicles.select`, { car: item?.fullname || item?.name })}
                </p>
              </div>
            </div>
          ))}
        /> 
        <div className="absolute z-[10] right-0 group py-6" onClick={() => updateIndex(+1)}>
          <BiSolidChevronRight className="text-[90px] text-[#C9C6C366] group-hover:text-[#C9C6C3]" />
        </div>
      </div>

    </>
  )
}


//  Page
// ===========================================================

function Page({ t, setSubpage }) {

  // Hooks
  const dispatch = useDispatch();
  const order = useSelector(state => state.book.order, () => true);
  const isMobile = useResponsiveDesign(1024);

  // Variables
  const [carIndex, setCarIndex] = useState(0);

  // Constants
  const cars = t('vehicles.cars', { returnObjects: true });

  // Change car
  function updatePrice(_newIndex) {
    dispatch(updateOrder({
      ...order,
      "vehicle": cars?.[_newIndex]?.id
    }));
  }

  // Change car
  function updateIndex(_val) {
    let newIndex = carIndex + _val;
    if (newIndex >= cars.length )
      newIndex = 0;
    else if (newIndex < 0 )
      newIndex = cars.length-1;
    setCarIndex(newIndex);
    updatePrice(newIndex)
  }

  // Select car
  function setCar(_val) {
    dispatch(updateOrder({ ...order, "vehicle": _val.id }))
    setSubpage(prevState => (prevState+1))
  }

  // Update vehicle on mount
  useEffect(() => {
    updatePrice(carIndex);
  }, []);
  

  // Page
  return (
    <div className="
      w-full flex flex-col justify-center items-center gap-6 md:gap-12 py-6
      text-white
    ">
      <Title t={t} />
      <Equipments t={t} />
      {(isMobile) 
        ? <CarsMobile   t={t} setCar={setCar} cars={cars} carIndex={carIndex} updateIndex={updateIndex} />
        : <CarsComputer t={t} setCar={setCar} cars={cars} carIndex={carIndex} updateIndex={updateIndex} />
      }
    </div>
  )
}

export default withTranslation()(Page)