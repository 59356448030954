//  Function
// ===========================================================

export default function fnRideDuration(order) {

  // Requirements
  let startH = parseInt(order?.timeStartHour, 10)
  let startM = parseInt(order?.timeStartMinute, 10)
  let endH = parseInt(order?.timeEndHour, 10)
  let endM = parseInt(order?.timeEndMinute, 10)

  // Set hour
  let hourDiff = (endH - startH)
  if (hourDiff < 0) {
    hourDiff = 24 + hourDiff
  }

  // Set minute
  let minuteDiff = (endM - startM)

  // Set duration in minute
  let duration = (hourDiff * 60) + minuteDiff

  // Real values
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  return (`${hours}h:${minutes}m`)
}
