//  Assets
// ===========================================================

import { FaArrowRightLong } from 'react-icons/fa6'


//  Component
// ===========================================================
export function HeroCard({ image, from, to, ride, description, price }) {
    return (
        <div className="w-full h-full flex flex-col">
            <div className={`
                w-full h-full
                rounded p-2 bg-[#E0DDDA]
                text-black hover:text-white
                hover:bg-[#000]
                border-[1px]
                hover:border-[#E0DDDA]
                group
            `}>
                <div className="relative w-full aspect-[5/3] rounded overflow-hidden flex justify-center items-center">
                    <img src={image} alt="versailles" className="absolute object-cover object-center h-[110%] w-[110%]" />
                </div>
                <div className="w-full rounded flex flex-col justify-around">
                    <p className="text-xl font-dm-regular flex items-center gap-4 p-1">
                        {ride
                            ? ride
                            : <>{from} <FaArrowRightLong /> {to}</>
                        }
                    </p>
                    <div className="w-full h-[1px] bg-[#000] group-hover:bg-[#E0DDDA]" />
                    <p className="text-base font-dm-thin p-1">
                        {description}
                    </p>
                </div>
            </div>
            <div className="w-full flex justify-center items-center pt-3">
                <div className="w-fit border border-white rounded-full py-1 px-3">
                    <p className="text-base font-dm-thin p-1">
                        {price}
                    </p>
                </div>
            </div>
        </div>
    )
}
