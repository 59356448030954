//  Component (local)
// ===========================================================

function Select({ background, border, text, id, defaultValue, values, onChange }) {
    return (
        <select 
            className={`
                ${background}
                border-[1px] ${border}
                font-dm-light text-lg text-center ${text}
                py-1 px-2
            `}
            id={id} 
            name={id} 
            defaultValue={defaultValue || values?.[0]}
            required={true}
            onChange={onChange}
        >
            {(values || []).map((item, index) =>
                <option 
                    key={`TIMEPICKER-${id}-${item}`} 
                    value={item} 
                >
                    {item}
                </option>
            )}
        </select>
    )
}

//  Component (base)
// ===========================================================

function TimePicker({
    className,
    id, 
    defaultValue, 
    onChange, 

    background, 
    border, 
    text
}) {
    return (
        <div 
            className={`
                ${className}
                flex flex-row justify-center items-center gap-2
            `}
        >
            <Select
                background={background}
                border={border}
                text={text}
                id={`${id}Hour`}
                defaultValue={defaultValue?.[0]}
                values={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}
                onChange={onChange}
            />
            <span className="uppercase">
                h
            </span>
            <Select 
                background={background}
                border={border}
                text={text}
                id={`${id}Minute`}
                defaultValue={defaultValue?.[1]}
                values={["00", "15", "30", "45"]}
                onChange={onChange}
            />
        </div>
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <TimePicker
            background="bg-transparent"
            border="border-[#000000]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <TimePicker
            background="bg-transparent"
            border="border-[#C9C6C3]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}