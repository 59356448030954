//  Component (base)
// ===========================================================

function Toggle({
    className, 
    values,
    value,
    setValue, 

    background, 
    border, 
    selected,
    text
}) {
    return (
        <div  
            className={`
                ${className}
                flex justify-center items-center
                rounded-sm
                border-[1px] ${border}
                overflow-hidden
            `}
        >
            {values.map((item, index) =>
                <button 
                    key={`toggle-${item.id}-${index}`} 
                    type="button"
                    className={`
                        w-full
                        px-6 py-1
                        uppercase
                        whitespace-nowrap
                        font-dm-light text-base text-center
                        ${(value === item.id) ? selected : text}
                    `}
                    onClick={() => setValue(item)}
                >
                    {item.value}
                </button>
            )}
        </div>
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <Toggle
            background="bg-[#000000]"
            border="border-[#000000]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <Toggle
            background="bg-transparent"
            border="border-[#C9C6C3]"
            text="text-white"
            selected="bg-[#C9C6C3] text-black"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}