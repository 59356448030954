//  Dependencies
// ===========================================================

import { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';


//  Modules
// ===========================================================

import useHttpsCallable from '#hooks/useHttpsCallable';


//  Route
// ===========================================================

function Route() {

  // Hooks
  const navigate = useNavigate();
  const stripeId = useSelector(state => state.book.stripe, () => true);

  // Cloud function
  const retrievePaymentIntent = useHttpsCallable("retrievePaymentIntent");
  
  // Variables
  const [paymentIntent, setPaymentIntent] = useState(null);

  // Retrieve
  useEffect(() => {
    if (!stripeId) {
      navigate(`/services`);
    } else {
      retrievePaymentIntent
        .call(stripeId)
        .then(res => {
          setPaymentIntent(res);
        });
    }
  }, []);


  // Route
  return (
    <main className="">
      <Outlet context={[ paymentIntent ]} />
    </main>
  )
}

export default (Route)