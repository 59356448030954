

export default {
    pt: {
        id: "pt",
        path: "/service/pt",
        img: "/images/services/private-transfer.webp",
        services: { 
            styles: { 
                container: {},
                img: { 
                    "position": "absolute", 
                    "bottom": "0px",
                    "width": "150%",
                    "maxWidth": "150%",
                    "height": "80%",
                }
            }
        }
    },
    ot: {
        id: "ot",
        path: "/service/ot",
        img: "/images/services2/on-time.jpeg",
        services: { 
            styles: { 
                container: {},
                img: { 
                    "position": "absolute", 
                    "bottom": "0px",
                    "width": "190%",
                    "maxWidth": "190%",
                    "height": "90%",
                }
            }
        }
    },
    ct: {
        id: "ct",
        path: "/service/ct",
        img: "/images/services/city-tour.webp",
        services: { 
            styles: { 
                container: {},
                img: { 
                    "position": "absolute", 
                    "top": "0px",
                    "width": "250%",
                    "maxWidth": "250%",
                    "height": "120%",
                }
            }
        }
    }
}

