//  Dependencies
// ===========================================================

import { useState } from "react"
import { withTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"


//  Modules
// ===========================================================

import { PopupCancel } from '#components/Popups'
import { BtnBlack, BtnBlackFilled, BtnWhite, BtnText } from '#components/Buttons'
import { TglBlack } from '#components/Toggles'
import { Input, Select } from '#components/Entries'
import useHttpsCallable from '#hooks/useHttpsCallable'


//  Constants
// ===========================================================

import COUNTRIES from '#constants/countries'
import STATES from '#constants/states'


//  Components (local)
// ===========================================================

function Field({ className, id, label, children }) {
  return (
    <div className={`
      ${className}
      w-full flex flex-col
      font-dm-light text-lg text-black
    `}>
      <label htmlFor={id} className="text-base text-[#636363] whitespace-nowrap">
        {label}
      </label>
      {children}
    </div>
  )
}


//  Sections
// ===========================================================

function Billing({ t, metadata }) {

  // Hooks
  const [type, setType] = useState(0)
  const [states, setStates] = useState(null)

  // Constants
  const toggle = [t('checkout.billing.perso'), t('checkout.billing.pro')]
  const toggleName = [t('checkout.billing.name'), t('checkout.billing.company')]

  // Country updated
  function onCountryChange(e) {
    e.preventDefault()
    const value = e?.target?.value
    const result = COUNTRIES.find(item => item.id === value)
    if (result?.filename)
      setStates(STATES[result.filename])
    else
      setStates(null)
  }


  // Block
  return (
    <div className="w-full max-w-[800px] lg:max-w-full flex flex-col lg:flex-row gap-6 p-6 bg-card rounded-xl">
      <div className="w-full flex flex-col gap-6">
        <TglBlack
          className="font-dm-light text-base text-black"
          data={toggle}
          value={type}
          setValue={(_index) => setType(_index)}
        />
        <Field id="name" label={toggleName[type]}>
          <Input.Black
            className="w-full" 
            id="name" 
            type="text" 
            required
          />
        </Field>
        <Field id="email" label={t('checkout.billing.email')}>
          <Input.Black
            className="w-full" 
            id="email" 
            type="email" 
            defaultValue={metadata?.email}
            required 
          />
        </Field>
        <Field id="phone" label={t('checkout.billing.phone')}>
          <Input.Black
            className="w-full" 
            id="phone" 
            type="tel" 
            defaultValue={metadata?.phone}
          />
        </Field>
      </div>
      <div className="w-full flex flex-col gap-6">
        <Field id="line1" label={t('checkout.billing.address')}>
          <Input.Black
            className="w-full" 
            id="line1" 
            type="text" 
            required
          />
        </Field>
        <Field id="line2" label={t('checkout.billing.complement')}>
          <Input.Black
            className="w-full" 
            id="line2" 
            type="text" 
          />
        </Field>
        <div className="w-full flex gap-12">
          <Field id="postal_code" label={t('checkout.billing.postal')}>
            <Input.Black
              className="w-full" 
              id="postal_code" 
              type="text" 
              required
            />
          </Field>
          <Field id="city" label={t('checkout.billing.city')}>
            <Input.Black
              className="w-full" 
              id="city" 
              type="text" 
              required
            />
          </Field>
        </div>
        <div className="w-full flex gap-12">
          <Field id="country" label={t('checkout.billing.country')}>
            <Select.Black
              id="country" 
              values={COUNTRIES} 
              onChange={onCountryChange}
            />
          </Field>
          {states
            ? <Field id="state" label={t('checkout.billing.state')}>
                <Select.Black
                  id="state" 
                  values={states || []} 
                />
              </Field>
            : <div className="w-full"></div>
          }
        </div>
      </div>
    </div>
  )
}

function Payment({ t, metadata, id }) {

  const prices = {
    ht: (Number(metadata?.price_eur_ttc)*0.8).toFixed(2),
    tva: (Number(metadata?.price_eur_ttc)*0.2).toFixed(2),
    ttc: (Number(metadata?.price_eur_ttc)).toFixed(2),
  }
  return (
    <div className="
      w-full max-w-[600px] grid gap-3
      font-dm-regular text-lg text-black
      lg:grid-cols-2 lg:gap-12 lg:max-w-full
    ">
      <div className="w-full p-6 bg-card rounded-xl">
        <div className="w-full h-full flex flex-col justify-evenly items-center gap-2 p-3 lg:p-6 border border-black rounded-xl">
          <div className="">
            <p className="text-xl text-center"> {t('checkout.payment.command')} </p>
            <p className="text-base text-center text-grey"> {id.slice(-6)} </p>
          </div>
          <div className="w-full font-dm-thin">
            <p className="w-full flex justify-between">
              <span> {t('checkout.payment.ht')} </span>
              <span> {prices.ht}€ </span>
            </p>
            <p className="w-full flex justify-between">
              <span> {t('checkout.payment.tva')} </span>
              <span> {prices.tva}€ </span>
            </p>
          </div>
          <div className="w-full h-[1px] bg-black" />
          <p className="w-full flex justify-between">
            <span> {t('checkout.payment.ttc')} </span>
            <span> {prices.ttc}€ </span>
          </p>
        </div>
      </div>
      <div className="w-full p-6 bg-card rounded-xl">
        <PaymentElement />
      </div>
    </div>
  )
}


//  Page
// ===========================================================

function Page({ t, paymentIntent, setSubpage }) {

  // Hooks
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  
  // Variables
  const [popupCancel, setPopupCancel] = useState(false)
  const [loading, setLoading] = useState(false)

  // Cloud function
  const cancelPaymentIntent = useHttpsCallable("cancelPaymentIntent")

  // Confirm
  const confirm = async (e) => {

    // Get form data
    e.preventDefault()
    const form = new FormData(e.target)
    const formDataObj = {}
    form.forEach((value, key) => (formDataObj[key] = value))

    // Requirements
    if (!stripe || !elements) {
      return
    }

    // Pay
    setLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        //return_url: 'https://example.com/',
        payment_method_data : {
          billing_details : {
            name: formDataObj?.name,
            email: formDataObj?.email,
            phone: formDataObj?.phone,
            address: {
              line1: formDataObj?.line1,
              line2: formDataObj?.line2,
              city: formDataObj?.city,
              state: formDataObj?.state,
              postal_code: formDataObj?.postal_code,
              country: formDataObj?.country,
            },
          }
        }
      },
    })
    setLoading(false)

    if (error) {
      toast.error(error?.message)
    } else {
      setSubpage(prevState => (prevState + 1))
    }
  }

  // Cancel
  const cancel = () => {
    cancelPaymentIntent.call(paymentIntent?.id)
      .then(res => navigate(`/`))
  }


  // Page
  return (
    <div className="w-full flex justify-center items-center p-3 lg:p-6  font-pf-regular px-3">
      <form onSubmit={confirm} className="w-full max-w-[1000px] flex flex-col items-center gap-6 lg:gap-12">
        <h1 className="text-4xl text-white text-center">
          {t('checkout.billing.title')}
        </h1>
        <Billing t={t} metadata={paymentIntent?.metadata} />
        <h1 className="text-4xl text-white text-center">
          {t('checkout.payment.title')}
        </h1>
        <Payment t={t} metadata={paymentIntent?.metadata} id={paymentIntent?.id} />
        <div className="w-full max-w-[300px] flex flex-col gap-3">
          <BtnWhite className="w-full" type="submit" disabled={loading}>
            {t('checkout.payment.confirm')}
          </BtnWhite>
          <BtnText className="text-white" type="button" onClick={() => setPopupCancel(true)} disabled={popupCancel}>
            {t('checkout.payment.cancel')}
          </BtnText>
        </div>
      </form>
      <PopupCancel
        hide={popupCancel !== true}
        text={t(`checkout.popup.cancel`)}
      >
        <BtnBlack className="w-full max-w-[150px]" onClick={() => cancel()} disabled={cancelPaymentIntent.loading}>
          {t(`global.yes`)}
        </BtnBlack>
        <BtnBlackFilled className="w-full max-w-[150px]" onClick={() => setPopupCancel(false)}>
          {t(`global.no`)}
        </BtnBlackFilled>
      </PopupCancel>
    </div>
  )
}

export default withTranslation()(Page)