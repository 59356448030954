//  Dependencies
// ===========================================================

import { Outlet } from "react-router-dom";
import { withTranslation } from 'react-i18next';


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet';
import { PopupOrder } from '#components/Popups';


//  Route
// ===========================================================

function Route({ t, i18n }) {

  // SEO (default)
  const headTags = t('home.headTags', { returnObjects: true });

  // Route
  return (
    <>
      <SEO headTags={headTags} />
      <main className="w-full h-full">
        <Outlet />
        <PopupOrder />
      </main>
    </>
  );
}

export default withTranslation()(Route);