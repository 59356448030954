//  Dependencies
// ===========================================================

import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateOrder } from '#stores/components/bookSlice';
import { useNavigate } from "react-router-dom";


//  Modules (layouts, assets, stores, components)
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'
import { Address, CheckBox, DatePicker, TimePicker } from '#components/Entries/index.js'
import Toggle from '#components/Toggle'


//  Component
// ===========================================================

function Field({ row, id, placeholder, children }) {
    return (
      <div className={`
        w-full flex
        ${row ? "flew-row items-center justify-between" : "flex-col"}
      `}>
        <label htmlFor={id} className="text-left text-[#C9C6C3] whitespace-nowrap">
          {placeholder}
        </label>
        { children }
      </div>
    )
}


export function HeroBook() {

    // Hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Services
    const [service, setService] = useState("pt");
    const services = [
        {
            id: "pt",
            value: t('home.hero.pt'),
        },
        {
            id: "ot",
            value: t('home.hero.ot'),
        }
    ];

    // Submit
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData(e.target)
        const formDataObj = {}
        form.forEach((value, key) => (formDataObj[key] = value))
        dispatch(updateOrder(formDataObj))
        navigate(`/order?service=${formDataObj.service}`)
    };


    return (
        <form
            className="
                w-full md:w-[350px] flex flex-col gap-6 text-center py-6 md:py-0
                font-dm-thin text-lg text-white
            "
            onSubmit={handleSubmit}
        >
            <input id="service" name="service" type="hidden" value={service} />
            <Toggle.White
                value={service}
                values={services}
                setValue={(_item) => setService(_item.id)}
            />
            <Field id="from" placeholder={t('order.from')}>
                <Address.White
                    className="w-full"
                    background="dark"
                    id="from"
                />
            </Field>
            {(service === "pt") &&
                <Field id="to" placeholder={t('order.to')}>
                    <Address.White
                        className="w-full"
                        background="dark"
                        id="to"
                    />
                </Field>   
            }
            {(service === "pt") &&
                <CheckBox.White
                    className="w-fit" 
                    id="roundTrip"
                    label={t('order.roundTrip')}
                />
            }
            <Field id="date" placeholder={t('order.date')}>
                <DatePicker.White
                    className="w-fit"
                    background="dark"
                    id="date"
                />
            </Field>
            <Field id="time" placeholder={t('order.time')}>
                <div className='w-full flex flex-wrap items-center gap-4'>
                <TimePicker.White
                    className="w-fit" 
                    id="timeStart" 
                />
                {(service === "ot") &&
                    <>
                        {t('global.to')}
                        <TimePicker.White
                            className="w-fit" 
                            id="timeEnd" 
                        />
                    </>
                }
                </div>
            </Field>
            <BtnWhite type="submit">
                {t('home.hero.book')}
            </BtnWhite>
        </form>
    )
}
