//  Component (base)
// ===========================================================

function Toggle({
    className, 
    value,
    setValue, 
    selected, 
    not_selected,
    border, 
    data
}) {
    return (
        <div  
            className={`
                ${className}
                flex justify-center items-center
                rounded-sm
                ${border}
                overflow-hidden
            `}
        >
            {data.map((item, index) =>
                <button 
                    key={`toggle-${index}-${item}`} 
                    type="button"
                    className={`
                        w-full
                        px-6 py-1
                        uppercase
                        whitespace-nowrap
                        text-center 
                        font-semibold
                        ${(value === index) ? selected : not_selected}
                    `}
                    onClick={() => setValue(index)}
                >
                    {item}
                </button>
            )}
        </div>
    )
}


//  Exports
// ===========================================================

export function TglBlack(props) {
    return (
        <Toggle
            border="border-[2px] border-black"
            selected="bg-black text-white "
            not_selected="text-black"
            {...props}
        />
    )
}

export function TglWhite(props) {
    return (
        <Toggle
            selected="bg-transparent hover:bg-white"
            border="border-[1px] border-white"
            text=""
            {...props}
        />
    )
}
