//  Dependencies
// ===========================================================

import { useState } from "react"
import { withTranslation } from 'react-i18next'


//  Modules (layouts, assets, stores, components)
// ===========================================================

import useWindowDimensions from '#hooks/useWindowDimensions'
import { BsChevronDoubleRight, BsChevronDoubleLeft } from 'react-icons/bs'
import { HeroBook } from '#components/Elements'
import { BtnWhiteFilled } from '#components/Buttons/index.jsx'
import HeroImg from '#assets/img/hero.webp'


//  Components (local)
// ===========================================================

function RightBlock({ t }) {

  // Traductions
  const title = t('home.hero.title', { returnObjects: true })

  return (
    <>
      <h1 className="
        font-pf-regular
        whitespace-pre-line
        text-5xl lg:text-6xl
      ">
        {title[0]} <span className="font-pf-italic">{title[1]}</span>
      </h1>
      <p className="font-dm-thin text-lg whitespace-pre-line lg:max-w-[350px] 2xl:max-w-full">
        {t('home.hero.description')}
      </p>
      <p className="text-lg font-dm-light uppercase flex items-center gap-3">
        <span className="w-[30px] h-[1px] bg-[#C9C6C380]" />
        {t('home.hero.welcome')}
      </p>
    </>
  )
}

function ComputerHero({ t }) {
  return (
    <>
      <div className="w-full flex items-start justify-between bg-hero bg-center bg-contain bg-no-repeat bg-opacity-50 my-[10vh]">
        <div className="w-full max-w-[550px] flex flex-col gap-6 text-white px-12">
          <RightBlock t={t} />
        </div>
        <div className="w-fit px-12">
          <HeroBook />
        </div>
      </div>
    </>
  )
}

function MobileHero({ t }) {

  // Hooks
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div className="w-[130%] max-w-[600px]">
        <img src={HeroImg} alt="hero-image" className="opacity-[80%]" />
      </div>
      {isOpen
        ? <div className="w-full px-6">
            <HeroBook />
          </div>
        : <div className="w-full flex flex-col gap-6 text-white px-6">
            <RightBlock t={t} />
          </div>
      }
      <BtnWhiteFilled onClick={() => setOpen(prevState => (!prevState))}>
        <div className="w-full flex items-center gap-3">
          {isOpen && <BsChevronDoubleLeft />}
          {isOpen ? t('global.return') : t('home.hero.book')}
          {!isOpen && <BsChevronDoubleRight />}
        </div>
      </BtnWhiteFilled>
    </>
  )
}


//  Component
// ===========================================================

function Hero({ t, animation }) {

  // Hooks
  const { width } = useWindowDimensions()

  // Traductions
  const title = t('home.hero.title', { returnObjects: true })

  return (
    <section
      id="home-hero" 
      className="w-full min-h-[85vh] flex flex-col items-center justify-center gap-6 pb-[10vh] lg:pt-[5vh] overflow-hidden"
    >
      {(width > 1024)
        ? <ComputerHero t={t} />
        : <MobileHero t={t} />
      }
    </section>

  )
}

export default withTranslation()(Hero)