//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";


//  Modules
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'


//  Components (local)
// ===========================================================

function Block({ title, content }) {
  return (
    <div className={`
      w-full md:w-1/2 md:max-w-[400px]
      flex flex-col
      gap-6
      text-white
      text-center
      px-3 md:px-6
    `}>
      <h3 className={`
        text-lg md:text-2xl
        font-dm-regular
        uppercase
      `}>
        {title}
      </h3>
      <p className={`
        text-base md:text-lg
        font-dm-thin
        whitespace-pre-line
      `}>
        {content}
      </p>
    </div>
  );
}

const customAnimation = keyframes`
  from {
    opacity: 0.15;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100vh;
  }
`;


//  Section
// ===========================================================

function Features({ t }) {

  // Hooks
  const navigate = useNavigate()

  return (
      <section 
        id="home-features"
        className="
          w-full h-full
          flex flex-col items-center
          py-[10vh] px-[5%]
        "
      >
        <Reveal 
          keyframes={customAnimation}
          duration={2500} 
          delay={300}
          triggerOnce={true}
          className="
            w-full max-w-[1200px] h-full
            border-y-[1px] border-[#C9C6C380]
            overflow-hidden
          "
        >
          <div className="
            w-full h-full
            flex flex-col items-center
            gap-12 md:gap-8
            py-12 md:py-8"
          >
            <div
              className={`
                w-full h-full
                relative
                flex
                justify-evenly
                items-center md:items-start
                flex-col md:flex-row
                gap-12 md:gap-0
              `}
            >
              <Block
                title={t('home.features.left.title')}
                content={t('home.features.left.content')}
              />
              <div className="w-1/3 md:w-[1px] h-[1px] md:h-full bg-[#C9C6C3] block md:absolute" />
              <Block
                title={t('home.features.right.title')}
                content={t('home.features.right.content')}
              />
            </div>
            <BtnWhite onClick={() => navigate('/services')}>
              {t('global.book')}
            </BtnWhite>
          </div>
        </Reveal>
      </section>
  )
}

export default withTranslation()(Features)