//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";


//  Components
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'


//  Assets
// ===========================================================

import { BsChevronDown } from 'react-icons/bs'


//  Component
// ===========================================================

const customAnimation = keyframes`
  from {
    opacity: 0.25;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100vh;
  }
`;

function Page({ t }) {

  // Hooks
  const navigate = useNavigate()

  // Texts
  const title = t('home.testimonials.title', { returnObjects: true })
  const description = t('home.testimonials.description', { returnObjects: true })


  return (
    <section id="home-driver" className="w-full flex flex-col justify-center items-center gap-12 text-white">
      <div className="w-[90%] md:w-[80%] max-w-[900px] flex flex-col md:text-center">
        <h2 className={`
          font-pf-regular
          whitespace-pre-line md:whitespace-normal
          text-5xl md:text-6xl
          pb-6
        `}>
          {title[0]} <span className="font-pf-italic">{title[1]}</span>
        </h2>
        <p className="text-lg font-dm-thin">
          {description[0]}
        </p>
        <p className="text-lg font-dm-regular uppercase">
          {description[1]}
        </p>
      </div>
      <Reveal 
        keyframes={customAnimation}
        duration={4000} 
        delay={300}
        triggerOnce={true}
        className="
          w-[90%] md:w-[80%]
          flex justify-center
          border-y-[1px] border-[#C9C6C380]
          overflow-hidden
        "
      >
        <div className="max-w-[800px] text-white text-center overflow-hidden py-6">
          <p className="text-lg font-dm-regular">
            {t('home.testimonials.content')}
          </p>
        </div>
      </Reveal>
      <ul className="w-[90%] md:w-[80%] flex flex-wrap justify-around gap-3">
        {t('home.services.list', { returnObjects: true }).map((item, index) => {
          return (
            <li  key={`SERVICES-${item.title}`} className="md:max-w-[350px] flex flex-col text-white md:text-center">
              <h3 className="flex items-end flex-row md:flex-col md:items-center gap-3">
                <span className="text-5xl md:text-8xl font-pf-regular">
                  {`0${index+1}.`}
                </span>
                <span className="text-xl font-dm-regular uppercase">
                  {item.title}
                </span>
                <BsChevronDown className="text-xl font-dm-regular uppercase hidden md:block" />
              </h3>
              <p className="text-lg font-dm-thin py-4 whitespace-pre-line">
                {item.description}
              </p>
            </li>
          );
        })}
      </ul>
      <BtnWhite onClick={() => navigate('/services')}>
        {t('home.services.book')}
      </BtnWhite>
    </section>
  )
}

export default withTranslation()(Page)