//  Assets
// ===========================================================

import { BsChevronDown } from 'react-icons/bs';


//  Component (base)
// ===========================================================

function Number({
    className,
    id, 
    values, 
    defaultValue, 
    onChange, 

    background, 
    border, 
    text
}) {
    return (
        <div 
            className={`
                ${className}
                relative
                flex flex-row justify-between items-center
                ${background}
                border-[1px] ${border}
                font-dm-light text-lg ${text}
            `}
        >
            <select 
                className="w-full text-center px-4 py-1"
                id={id}
                name={id}
                defaultValue={defaultValue || values?.[0]}
                required={true}
                onChange={onChange}
            >
                {values.map(item =>
                    <option key={`NUMBER-${id}-${item}`} value={item}>
                        {item}
                    </option>
                )}
            </select>
            <BsChevronDown className="absolute right-1 pointer-events-none" />
        </div>
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <Number
            background="bg-transparent"
            border="border-[#000000] focus:border-[#00000080]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <Number
            background="bg-transparent"
            border="border-[#C9C6C3] focus:border-[#ffffff]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}