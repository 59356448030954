//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next';


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet';
import { BsArrowUp } from 'react-icons/bs';
import useScrollPosition from '#hooks/useScrollPosition';
import Car from '#assets/img/aja.webp';


//  Sections
// ===========================================================

import Hero from './sections/hero';
import Features from './sections/features';
import Popular from './sections/popular';
import Driver from './sections/driver';
import Contact from './sections/contact';


//  Components (local)
// ===========================================================

function GoToTop() {

  // Hooks
  const { scrollY } = useScrollPosition();

  // Hide
  if (scrollY < 400)
    return null;

  // Show
  return (
    <button 
      type="button"
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      className="
        md:hidden
        fixed z-[10] right-5 bottom-5
        rounded-full border border-[2px] border-white hover:bg-white
        text-white hover:text-black
        p-2 sm:p-3
        opacity-70
      "
    >
      <BsArrowUp className="text-3xl sm:text-4xl"/>
    </button>
  );
}


//  Page
// ===========================================================

function Page({ t, i18n }) {

  // SEO
  const headTags = t('home.headTags', { returnObjects: true });

  // Page
  return (
    <>
      <SEO headTags={headTags} />
      <GoToTop />
      <Hero />
      <Features />
      <Popular />
      <Driver />
      <img src={Car} alt="versailles" className="w-full object-cover object-center opacity-80" />
      <Contact />
    </>
  );
}

export default withTranslation()(Page);