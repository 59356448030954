//  Component (base)
// ===========================================================

function DatePicker({
    className,
    id, 
    defaultValue, 
    onChange, 

    background, 
    border, 
    text
}) {

    // Min & default value
    var curr = new Date();
    var date = curr.toISOString().substring(0,10);

    return (
        <input 
            className={`
                ${className}
                ${background}
                border-[1px] ${border}
                font-dm-light text-lg ${text}
                p-1
            `}
            type="date"
            id={id}
            name={id}
            min={date}
            defaultValue={defaultValue || date}
            onChange={onChange}
            required={true}
        />
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <DatePicker
            background="bg-transparent"
            border="border-[#000000]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <DatePicker
            background="bg-transparent"
            border="border-[#C9C6C3]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}