//  Dependencies
// ===========================================================

import { useTranslation } from 'react-i18next'


//  Modules
// ===========================================================

import { LogoFull } from '#components/Elements'
import DATA from '#constants/footer.json'


//  Components (local)
// ===========================================================

function FooterLinks({ data }) {

  // Hooks
  const { t } = useTranslation()

  return (
    <nav className="w-full h-full">
      <ul className="
        w-full h-full flex flex-col items-center justify-evenly gap-2 
        font-dm-regular text-white text-xs text-center uppercase 
        lg:flex-row lg:gap-0
      ">
        {data?.map((link, index) => (
          <li key={link.label} className="px-2">
            {(link?.attributes)
              ? <a {...link.attributes}>
                  {t(link.label)}
                </a>
              : <p>
                  {t(link.label)}
                </p>
            }
          </li>
        ))}
      </ul>
    </nav>
  )
}


//  Component
// ===========================================================

export const Footer = () => {
  return (
    <footer className="w-full flex flex-col items-center">
      <div className="w-full h-[1px] bg-[#C9C6C380] lg:w-[90%]" />
      <div className="
        w-full flex items-center flex-col gap-6 py-6
        lg:flex-row lg:gap-0
      ">
        <LogoFull className="w-[250px] block lg:hidden" />
        <FooterLinks data={DATA.left} />
        <LogoFull className="w-[500px] hidden lg:block" />
        <FooterLinks data={DATA.right} />
      </div>
    </footer>
  );
};
