//  Modules (layouts, assets, stores, components)
// ===========================================================

import i18n from '#services/i18n'
import { toast } from 'react-toastify'


//  Function
// ===========================================================

export default function fnMinRideDuration(order) {

  // Requirements
  let startH = parseInt(order?.timeStartHour, 10)
  let startM = parseInt(order?.timeStartMinute, 10)
  let endH = parseInt(order?.timeEndHour, 10)
  let endM = parseInt(order?.timeEndMinute, 10)

  // Set hour
  let hourDiff = (endH - startH)
  if (hourDiff < 0) {
    hourDiff = 24 + hourDiff
  }

  // Set minute
  let minuteDiff = (endM - startM)

  // Set duration in minute
  let duration = (hourDiff * 60) + minuteDiff

  // Check duration is more than 3 hours
  if ( duration < (60*3) ) {
    toast.error(i18n.t('toast.error.duration'))
    return false
  }

  return true
}
