//  Dependencies
// ===========================================================

import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';


//  Modules
// ===========================================================

import { updateOrder } from '#stores/components/bookSlice';
import { BtnWhiteFilled, BtnText } from '#components/Buttons/index.jsx';
import { Textarea, Input, Number, Address, DatePicker, TimePicker, Select, CheckBox } from '#components/Entries/index.js'


//  Functions
// ===========================================================

import fnMinRideDuration from '#functions/fnMinRideDuration'
import fnMinStartTime from '#functions/fnMinStartTime'


//  Constants
// ===========================================================

import VEHICLES from '#constants/vehicles'


//  Components (local)
// ===========================================================

function Field({ className, hide, row, center, id, label, children }) {

  // Hide
  if ( hide )
    return null

  // Show
  return (
    <div className={`
      ${className}
      w-full flex
      ${row ? "flex-col lg:flex-row lg:items-center lg:justify-between" : "flex-col"}
      ${center ? "items-center justify-center" : ""}
      font-dm-light text-lg text-black
    `}>
      <label htmlFor={id} className="text-base text-grey whitespace-nowrap">
        {label}
      </label>
      {children}
    </div>
  )
}

function Price({}) {

  // Hooks
  const { t } = useTranslation();
  const price = useSelector(state => state.book.price);

  return (
    <Field id="price" label={t('global.rate')}>
      <p className="w-full">
        {(price > 0) 
          ? `${price?.toFixed(2)}€`
          : (price === -1) 
            ? t('global.no_price')
            : "---"
        }
      </p>
    </Field>
  )
}


//  Content
// ===========================================================

function UserInformation({ t, order }) {

  // Hooks
  const dispatch = useDispatch();
  const store = useStore();

  // Constants 
  const cars = t(`cars`, { returnObjects: true })

  // Input updated
  function onChange(e) {
    if (e?.target?.id)
      e.preventDefault();
    const id = (e?.target?.id) ? e.target.id : e.id;
    const value = (e?.target?.value) ? e.target.value.toString() : e.value.toString();
    const lastOrder = store.getState().book.order;
    const newOrder = { ...lastOrder, [id]: value };
    dispatch(updateOrder(newOrder));

    // Checks
    if ( id.includes("date") || id.includes("time") ) {

      // Check min start time
      fnMinStartTime(newOrder);
      
      // Check duration time
      if (newOrder.service === "ot")
        fnMinRideDuration(newOrder);
    }
  }


  return (
    <>
      <Field id="from" label={t('order.from')}>
        <Address.Black
          className="w-full" 
          id="from" 
          defaultValue={order?.from} 
          defaultPlace={order?.fromId} 
          defaultCity={order?.fromCity} 
          onChange={onChange}
          required 
        />
      </Field>
      <Field id="to" label={t('order.to')} hide={(order.service !== "pt")}>
        <Address.Black
          className="w-full" 
          id="to" 
          defaultValue={order?.to} 
          defaultPlace={order?.toId} 
          defaultCity={order?.toCity} 
          onChange={onChange}
          required 
        />
      </Field>
      <Field id="roundTrip" hide={(order.service !== "pt")}>
        <CheckBox.Black
          className="w-fit" 
          id="roundTrip" 
          label={t('order.roundTrip')} 
          defaultValue={order?.roundTrip === 'true'} 
          onChange={onChange}
        />
      </Field>
      <Field id="date" label={t('order.date')}>
        <DatePicker.Black
          className="w-fit" 
          id="date" 
          defaultValue={order?.date} 
          onChange={onChange}
        />
      </Field>
      <Field id="time" label={t('order.time')}>
        <div className='w-full flex flex-wrap items-center gap-4'>
          <TimePicker.Black
            className="w-fit" 
            id="timeStart" 
            defaultValue={[order?.timeStartHour, order?.timeStartMinute]}
            onChange={onChange}
          />
          {(order.service === "ot") &&
            <>
              {t('global.to')}
              <TimePicker.Black
                className="w-fit" 
                id="timeEnd" 
                defaultValue={[order?.timeEndHour, order?.timeEndMinute]}
                onChange={onChange}
              />
            </>
          }
        </div>
      </Field>
      <Field id="vehicle" label={t('order.vehicle')}>
        <Select.Black
          id="vehicle" 
          values={cars} 
          defaultValue={order?.vehicle} 
          onChange={onChange}
        />
      </Field>
      <Field id="cityTour" label={t('order.cityTour')} hide={(order.service !== "ct")}>
        <Textarea.Black
          className="w-full"
          rows="3"
          id="cityTour"
          defaultValue={order?.cityTour}
        />
      </Field>
      <Price />
    </>
  )
}

function TripInformation({ t, order }) {

  // Constants
  const SEATS = [...Array((VEHICLES[order?.vehicle]?.seat || 0) + 1).keys()];
  const LUGGAGES = [...Array((VEHICLES?.[order?.vehicle]?.luggage || 0) + 1).keys()];
  const LUGGAGESMORE = [...Array(11).keys()];

  return (
    <>
      <Field id="email" label={t('order.email')}>
        <Input.Black 
          id="email" 
          type="email" 
          placeholder="john.doe@mail.com"
          defaultValue={order?.email} 
          autoComplete="on"
          required 
        />
      </Field>
      <Field id="phone" label={t('order.phone')}>
        <Input.Black  
          id="phone" 
          type="tel" 
          placeholder="+33 6 00 00 00 00"
          defaultValue={order?.phone}
          autoComplete="on"
        />
      </Field>
      <Field id="passengers" label={t('order.passengers')}>
        <div className="w-full flex gap-6 pt-1">
          <Number.Black
            className="w-1/2"
            id="adults"
            values={SEATS.map(x => `${x} ${t('order.adults')}`)}
            defaultValue={order?.adults}
          />
          <Number.Black 
            className="w-1/2"
            id="children"
            values={SEATS.map(x => `${x} ${t('order.children')}`)}
            defaultValue={order?.children}
          />
        </div>
      </Field>
      <Field id="luggages" label={t('order.luggages')} row>
        <Number.Black 
          className="w-[100px]" 
          id="luggages" 
          defaultValue={order?.luggages} 
          values={LUGGAGES} 
        />
      </Field>
      <Field id="luggagesMore" label={t('order.luggagesMore')} row>
        <Number.Black 
          className="w-[100px]" 
          id="luggages" 
          defaultValue={order?.luggagesMore || 0} 
          values={LUGGAGESMORE} 
        />
      </Field>
      <Field id="childSeats" label={t('order.childSeats')} row>
        <Number.Black 
          className="w-[100px]" 
          id="childSeats" 
          defaultValue={order?.childSeats} 
          values={SEATS} 
        />
      </Field>
      <Field id="boosterSeats" label={t('order.boosterSeats')} row>
        <Number.Black 
          className="w-[100px]" 
          id="boosterSeats" 
          defaultValue={order?.boosterSeats} 
          values={SEATS} 
        />
      </Field>
      <Field id="welcomePanel" label={t('order.welcomePanel')}>
        <Input.Black  
          id="welcomePanel" 
          type="text" 
          placeholder="M. John Doe"
          defaultValue={order?.welcomePanel} 
          autoComplete="off"
        />
      </Field>
    </>
  )
}


//  Page
// ===========================================================

function Page({ t, i18n, setSubpage }) {

  // Hooks
  const dispatch = useDispatch();
  const order = useSelector(state => state.book.order, () => true);

  // Confirm
  const confirm = (e) => {

    // Get form data
    e.preventDefault();
    const form = new FormData(e.target)
    const formDataObj = { ...order }
    form.forEach((value, key) => (formDataObj[key] = value))
    
    // Check min start time
    const booktime = fnMinStartTime(formDataObj)
    if (booktime !== true)  return

    // Check duration time
    if (order.service === "ot") {
      const duration = fnMinRideDuration(formDataObj)
      if ( duration !== true )  return
    }

    // Update and next
    dispatch(updateOrder(formDataObj))
    setSubpage(prevState => (prevState+1))
  }

  // Previous
  const previous = () => {
    setSubpage(prevState => (prevState-1))
  }


  // Page
  return (
    <form
      onSubmit={confirm} 
      className="flex flex-col justify-center items-center gap-6 md:gap-12 p-3 md:p-12"
    >
      <input id="language" name="language" type="hidden" value={i18n?.language} />
      <div className="w-full flex lg:max-w-[1300px] flex-col lg:flex-row gap-6 md:gap-12">
        <div className="w-full flex flex-col gap-6 p-6 md:p-12 bg-card rounded-xl">
          <UserInformation t={t} order={order} />
        </div>
        <div className="w-full flex flex-col gap-6 p-6 md:p-12 bg-card rounded-xl">
          <TripInformation t={t} order={order} />
        </div>
      </div>
      <div className="w-full max-w-[800px] flex flex-col font-dm-light text-white">
        <label htmlFor="request" className="text-base md:text-lg text-center">
          {t('order.request')}
        </label>
        <Textarea.White
          className="w-full"
          rows="3"
          id="request"
          placeholder={t('order.requestMore')}
          defaultValue={order?.request}
        />
      </div>
      <div className="w-full max-w-[300px] flex flex-col gap-3">
        <BtnWhiteFilled type="submit">
          {t('details.next')}
        </BtnWhiteFilled>
        <BtnText type="button" className="text-white" onClick={previous}>
          {t('details.previous')}
        </BtnText>
      </div>
      <div className="w-full">
        <p className="text-base text-white">
          {t('details.luggagesMoreInfo')}
        </p>
      </div>
    </form>
  )
}


export default withTranslation()(Page)