//  Dependencies
// ===========================================================

import { BrowserRouter, Route, Routes } from "react-router-dom"


//  Modules
// ===========================================================

import { Header } from '#components/Header'
import { Footer } from '#components/Footer'
import { ScrollToTop } from '#components/Elements'
import { RouteHome, RouteOrder, RouteCheckout } from '#routes'


//  Pages
// ===========================================================

import Home from "#pages/home/index"
import Services from "#pages/services/default"
import ServicePT from "#pages/services/pt"
import ServiceOT from "#pages/services/ot"
import ServiceCT from "#pages/services/ct"
import Terms from "#pages/terms/index"
import Order from "#pages/order/index"
import Checkout from "#pages/checkout/index"
import Error404 from "#pages/404/index"


//  Router
// ===========================================================

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/:locale?" element={<RouteHome />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Error404 />} />
          <Route path="services" element={<Services />} />
          <Route path="service/pt" element={<ServicePT />} />
          <Route path="service/ot" element={<ServiceOT />} />
          <Route path="service/ct" element={<ServiceCT />} />
          <Route path="terms" element={<Terms />} />
          <Route path="legal" element={<></>} />
        </Route>
        <Route path="/:locale?" element={<RouteOrder />}>
          <Route path="order" element={<Order />} />
        </Route>
        <Route path="/:locale?" element={<RouteCheckout />}>
          <Route path="checkout" element={<Checkout />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
