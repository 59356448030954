//  Dependencies
// ===========================================================

import { Helmet } from 'react-helmet-async';


//  Component
// ===========================================================

export const SEO = (props) => {
    
    const { headTags: { title, description, keywords } } = props;

    return (
        <Helmet defer={false}>
            { (title) && 
                <>
                    <title>{title}</title>
                    <meta property='og:title' content={title} />
                    <meta name='twitter:title' content={title} />
                </>
            }
            { (description) && 
                <>
                    <meta name='description' content={description} />
                    <meta property='og:description' content={description} />
                    <meta name='twitter:description' content={description} />
                </>
            }
            { (keywords) && 
                <meta property='keywords' content={keywords} />
            }
            <script key="function" id="facebook-pixel-script">
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '360078533358651'); 
                    fbq('track', 'PageView');
                `}
            </script>
            <noscript key="image" id="facebook-pixel-image">
                {`<img height="1" width="1" src="https://www.facebook.com/tr?id=360078533358651&ev=PageView&noscript=1" />`}
            </noscript>
        </Helmet>
    );
}
