//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BsChevronDoubleRight } from 'react-icons/bs';


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet';
import SERVICES from '#constants/services.js';


//  Functions
// ===========================================================

import useResponsiveDesign from '#hooks/useResponsiveDesign';


//  Components (local)
// ===========================================================

function Title({ children }) {
  return (
    <div className="
      w-fit h-fit
      flex items-center gap-3
      border border-white
      rounded-full 
      pl-4 pr-3 pt-1 pb-2
    ">
      <h2 className="font-pf-regular text-2xl">
        {children}
      </h2>
      <BsChevronDoubleRight size={24}  className='pt-1'/>
    </div>
  );
}


//  Page
// ===========================================================

function Computer({ t }) {

  // Hooks
  const navigate = useNavigate();


  return (
    <div className="w-full h-[800px] flex justify-center">
      <div className="w-full max-w-[2000px] h-[800px] flex">
        {Object.keys(SERVICES || {}).map(item => {
          return (
            <div
              key={item} 
              className={`
                relative
                w-full h-full 
                flex justify-center items-center
                overflow-hidden
                group cursor-pointer
              `}
            >
              {/* <div className="absolute z-[-1] opacity-40" style={SERVICES[item].services.style} /> */}
              <div className="absolute inset-0 z-[-1] w-full h-full flex justify-center" >
                <img src={SERVICES[item].img} alt={item} style={SERVICES[item].services.styles.img} className="opacity-50" />
              </div>
              <button 
                type="button"
                className="w-full h-3/5 flex flex-col justify-start items-center gap-3 text-white p-3"
                onClick={() => navigate(SERVICES[item].path)}
              >
                <Title> {t(`services.${item}.title`)} </Title>
                <div className="max-w-[400px]">
                  <p className="font-dm-thin text-base text-center">
                    {t(`services.${item}.description`)}
                  </p>
                </div>
              </button>
          </div>
          )
        })}
      </div>
    </div>
  );
}

function Mobile({ t }) {

  // Hooks
  const navigate = useNavigate();


  return (
    <div className="w-full text-white">
      {Object.keys(SERVICES || {}).map(item => (
        <div
          key={item} 
          className="
            relative w-full h-full min-h-[300px]
            flex justify-center items-center
            overflow-hidden
          "
        >
          <div className="absolute inset-0 z-[-1] w-full h-full flex justify-center items-center">
            <img src={SERVICES[item].img} alt={item} className="w-full h-[130%] opacity-40 object-cover" />
          </div>
          <button 
            type="button"
            className="w-full h-full flex flex-col justify-center gap-3 text-white p-3 sm:items-center"
            onClick={() => navigate(SERVICES[item].path)}
          >
            <Title> {t(`services.${item}.title`)} </Title>
            <div className="w-[90%] sm:w-[60%]">
              <p className="font-dm-thin text-base text-left sm:text-center">
                {t(`services.${item}.description`)}
              </p>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}


//  Page
// ===========================================================

function Content({ t }) {

  // Hooks
  const isMobile = useResponsiveDesign(1024);


  return (
    <div className="relative w-full flex flex-col">
      <div className="w-fit flex flex-col gap-3 p-3 sm:w-full sm:items-center sm:p-6 lg:absolute">
        <h1 className="font-pf-regular text-3xl text-white uppercase sm:text-4xl">
          {t(`services.title`)}
        </h1>
        <div className="w-[70px] h-[1px] bg-white" />
      </div>
      {(isMobile) 
        ? <Mobile t={t} />
        : <Computer t={t} />
      }
    </div>
  );
}

function Page({ t }) {

  // SEO (default)
  const headTags = t('services.headTags', { returnObjects: true });

  return (
    <>
      <SEO headTags={headTags} />
      <Content t={t} />
    </>
  );
}


export default withTranslation()(Page);