//  Dependencies
// ===========================================================

import { Fragment, useRef, useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";


//  Modules
// ===========================================================

import { updatePrice } from '#stores/components/bookSlice'


//  Functions
// ===========================================================

import useHttpsCallable from '#hooks/useHttpsCallable';


//  Sections
// ===========================================================

import Vehicles from './vehicles/index';
import Details from './details/index';
import Summary from './summary/index';
import Confirmation from '#pages/confirmation/index';


//  Functions (local)
// ===========================================================

function getKeys(_service) {
  let keys;
  switch (_service) {
    case "pt":
      keys = ["fromId", "toId", "fromCity", "toCity", "roundTrip", "vehicle"];
      break;
    case "ot":
      keys = ["timeStartHour", "timeStartMinute", "timeEndHour", "timeEndMinute", "vehicle"];
      break;
    case "ct":
      keys = ["vehicle"];
      break;
    default:
      keys = [];
      break;
  }
  return keys;
}

function checkOrders(_keys, _oldOrder, _newOrder) {
  for (const key of _keys) {
    if ( _newOrder?.[key] !== _oldOrder?.[key] ) {
      return false;
    }
  }
  return true;
}


//  Components (local)
// ===========================================================

function Top({ subpage }) {

  // Go to top (subpage change)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [, subpage]);

  // Page
  return (null);
}

function NavigPhone({ t, subpage }) {

  // Constants
  const pages = t('order.pages', { returnObjects: true });

  // Page
  return (
    <div className="relative w-full justify-center items-start flex pt-12 pb-6 md:hidden">
      <div className="w-full justify-around items-center flex">
        {(pages || []).map((item, index) => (
          <div
            key={`PAGE-MENU-${item}`} 
            className="w-fit flex flex-col justify-center items-center pt-[1px]"
          >
            <div className={`
              w-[10px] z-[1] aspect-square rounded-full border border-[#C9C6C3] 
              ${(subpage === index) ? "bg-[#C9C6C3]" : "bg-[#000000]"}
            `} />
            <p className="font-dm-bold text-base text-white"> {item} </p>
          </div>
        ))}
      </div>
      <div className="absolute z-[0] w-full h-[10px] flex justify-center items-center">
        <div className="w-full h-[1px] bg-[#C9C6C3]" />
      </div>
    </div>
  )
}

function NavigComputer({ t, subpage }) {

  // Constants
  const pages = t('order.pages', { returnObjects: true });

  // Page
  return (
    <div className="relative w-full justify-center items-start pt-12 pb-6 hidden md:flex">
      <div className="w-full justify-around items-center flex">
        {(pages || []).map((item, index) => (
          <Fragment key={`PAGE-MENU-${item}`} >
            <div
              className={`
                w-full max-w-[200px] rounded-[2px] border border-[#C9C6C3] px-6 py-1
                flex justify-center items-center
                ${(subpage === index) ? "bg-[#C9C6C3] text-black" : "bg-[#000000] text-white"}
            `}>
              <p className="font-dm-normal text-base"> {item} </p>
            </div>
            {((index+1) < pages?.length) &&
              <BsArrowRight className="text-white text-4xl" />  
            }
          </Fragment>
        ))}
      </div>
    </div>
  )
}

function SetPrice({}) {

  // Hooks
  const dispatch = useDispatch();
  const order = useSelector(state => state.book.order);

  // Variables
  const lastOrder = useRef({});
  const lastPrice = useRef(undefined);

  // Cloud function
  const getPrice = useHttpsCallable("getServicePrice");

  // Local function
  async function update(_keys, _order) {
    try {
      for (const key of _keys) {
        if ( !_order?.[key] ) {
          return;
        }
      }
      console.log("getPrice", _keys, _order);
      const price = await getPrice.call(_order);
      if (price) {
        return (price);
      } else {
        return (-1);
      }
    } catch (error) {
      return;
    }
  }

  // Debounce (update price)
  useEffect(() => {
    let delayDebounceFn;
    const keys = getKeys(order.service);
    const isSame = checkOrders(keys, lastOrder.current, order);
    if (isSame === false) {
      dispatch(updatePrice(undefined));
      delayDebounceFn = setTimeout(async (_keys, _order) => {
        let price = await update(_keys, _order);
        dispatch(updatePrice(price));
        lastOrder.current = _order;
        lastPrice.current = price;
      }, 2000, keys, order);
    } else {
      dispatch(updatePrice(lastPrice?.current));
    }
    return () => clearTimeout(delayDebounceFn);
  }, [order]);

  // Page
  return (null);
}


//  Page
// ===========================================================

function Page({ t }) {

  // Hooks
  const [subpage, setSubpage] = useState(0)

  // Page Content
  function OrderContent() {
    switch (subpage) {
      case 0:
        return <Vehicles setSubpage={setSubpage} />
      case 1:
        return <Details setSubpage={setSubpage} />
      case 2:
        return <Summary setSubpage={setSubpage} />
      case 3:
        return <Confirmation setSubpage={setSubpage} />
      default:
        return null
    }
  }


  // Page
  return (
    <>
      <Top subpage={subpage} />
      <NavigPhone t={t} subpage={subpage} />
      <NavigComputer t={t} subpage={subpage} />
      <OrderContent />
      <SetPrice />
    </>
  )
}


export default withTranslation()(Page)