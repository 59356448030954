//  Dependencies
// ===========================================================

import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


//  Configuration (For Firebase JS SDK v7.20.0 and later, measurementId is optional)
// ===========================================================

const firebaseConfig = {
  apiKey: "AIzaSyC2PDkNhzfumIuL4MKA7YkY1TUB6UAgSnY",
  authDomain: "aja-prestige.firebaseapp.com",
  projectId: "aja-prestige",
  storageBucket: "aja-prestige.appspot.com",
  messagingSenderId: "1096379874444",
  appId: "1:1096379874444:web:09fbcb49e88abad1700fa9",
  measurementId: "G-Q1REZRG9G3"
};


//  App
// ===========================================================

let app;
try {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
  // site key and pass it to initializeAppCheck().
  if (window.location.hostname === "localhost") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN=true
  }
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider("6LdYtBApAAAAAAj1m7MM9dW48P_PJQcmLlApb0hH"),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

//  Exports
// ===========================================================

export const functions = getFunctions(app);


//  Emulator
// ===========================================================

// if (window.location.hostname === "localhost") {
//   connectFunctionsEmulator(functions, "127.0.0.1", 5001);
// }