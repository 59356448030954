//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet'
import { Address, DatePicker, TimePicker } from '#components/Entries/index.js'
import { BtnBlack } from '#components/Buttons/index.jsx'
import { LayoutService } from '#layouts'


//  Assets
// ===========================================================

import Background from '#assets/img/on-time.jpg'


//  Components (local)
// ===========================================================

function Field({ className, row, id, label, children }) {
  return (
      <div className={`
          ${className}
          w-full flex
          ${row ? "flew-row items-center justify-between" : "flex-col"}
          font-dm-light text-lg text-black
      `}>
          <label htmlFor={id} className="text-base text-[#636363] whitespace-nowrap">
              {label}
          </label>
          { children }
      </div>
  )
}


//  Page
// ===========================================================

function Page({ t }) {

  // Constants
  const service = "ot";

  // SEO
  const headTags = t(`${service}.headTags`, { returnObjects: true })

  // Variable
  const title = t(`${service}.title`)
  const subtitle = t(`${service}.subtitle`)
  const description = t(`${service}.description`, { returnObjects: true })
  const carousel = t(`${service}.carousel`, { returnObjects: true })

  
  // Page
  return (
    <>
      <SEO headTags={headTags}/>
      <LayoutService
        background={Background}
        title={title}
        subtitle={subtitle}
        left={description}
        carousel={carousel}
      >
        <div className="
          w-full max-w-[400px] h-fit p-6
          bg-[#E0DDDA] rounded-2xl 
          flex flex-col justify-evenly gap-3
          font-dm-regular text-base text-black whitespace-nowrap
        ">
          <input id="service" name="service" type="hidden" value={service} />
          <Field id="from" label={t('order.from')}>
            <Address.Black
              className="w-full" 
              id="from" 
              required 
            />
          </Field>
          <Field id="date" label={t('order.date')}>
            <DatePicker.Black
              className="w-fit" 
              id="date" 
            />
          </Field>
          <Field id="timeStart" label={t('order.time')}>
            <div className='w-full flex flex-wrap items-center gap-4'>
              <TimePicker.Black
                className="w-fit" 
                id="timeStart" 
              />
              {t('global.to')}
              <TimePicker.Black
                className="w-fit" 
                id="timeEnd" 
              />
            </div>
          </Field>
          <BtnBlack className="w-full mt-6" type="submit">
            {t('global.next')}
          </BtnBlack>
        </div>
      </LayoutService>
    </>
  )
}

export default withTranslation()(Page)
