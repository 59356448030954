//  Dependecies
// ===========================================================

import { useEffect, useRef, useState } from "react"
import useWindowDimensions from '#hooks/useWindowDimensions'

//  Modules
// ===========================================================

export function Carousel({ data }) {

  // Hooks
  const cardsRef = useRef(new Object())
  const { height, width } = useWindowDimensions()
  const [ cardWidth, setCardWidth ] = useState(0)
  const [ indexShow, setIndexShow ] = useState(0)

  // Update cards width
  useEffect(() => {
    const carouselId = document.getElementById("carousel")
    setCardWidth(carouselId?.offsetWidth)
  }, [width])

  // Update auto scroll
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndexShow((prevState) => {
        if ( prevState === undefined ) {
          scrollTo(0)
          return 0
        }
        const newState = prevState + 1
        if (newState < data.length)
          scrollTo(newState)
        else
          scrollTo(0)
      })
    }, 6000)
    return () => clearTimeout(timeout)
  }, [indexShow])

  // On scroll
  const onScroll = () => {
    const scrollPos = document.getElementById("carousel").scrollLeft
    for (const index in cardsRef?.current) {
      const { offsetLeft, offsetWidth } = cardsRef?.current[index]
      if ( (scrollPos >= offsetLeft)
          && (scrollPos < (offsetLeft + offsetWidth)) ) {
            setIndexShow(Number(index))
          break
      }
    }
  }

  // Scroll to (points)
  const scrollTo = (_index) => {
    const carousel = document.getElementById("carousel")
    carousel.scrollTo({
      left: cardsRef?.current[_index].offsetLeft, 
      behavior: 'smooth'
    })
  }


  return (
      <div className="w-full h-full flex flex-col">
        <div 
          id="carousel"
          onScroll={onScroll}
          className={`
              relative w-full h-full
              grid auto-cols-max grid-flow-col
              overflow-y-hidden overflow-x-scroll
              snap-x snap-mandatory
              scrollbar-none
          `}
        >
          {data.map((item, index) =>
            <div 
              key={`carousel-cards-${index}`}
              ref={(element) => cardsRef.current[index] = element}
              style={{ width: cardWidth }}
              className="snap-center"
            >
              {item}
            </div>
          )}
        </div>
        <div className="w-full h-fit flex justify-center p-3 gap-1">
          {data.map((item, index) =>
            <div 
              onClick={() => scrollTo(index)}
              key={`carousel-dots-${index}`}
              className="p-3"
            >
              <div className={`w-2 aspect-square rounded-full border border-white ${index === indexShow ? "bg-white" : ""}`} />
            </div>
          )}
        </div>
      </div>
  )
}