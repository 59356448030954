//  Dependencies
// ===========================================================

import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


//  Constants
// ===========================================================

import LANGUAGES from '#constants/languages.json'


//  Component
// ===========================================================

export function Lang({ className, onClick }) {

    // Hooks
    const navigate = useNavigate()
    const location = useLocation()
    const { i18n } = useTranslation()

    // Constants
    const langArray = Object.keys(LANGUAGES || {})

    // Function
    function changeLanguage(_lang) {
        const path = location.pathname
        const lang = i18n.language
        if (lang === _lang) {
            return
        } else if ( path.includes(`/${lang}`) ) {
            const newPath = path.replace(`/${lang}`, `/${_lang}`)
            i18n.changeLanguage(_lang)
            navigate(newPath)
        } else {
            const newPath = `/${_lang}${path}`
            i18n.changeLanguage(_lang)
            navigate(newPath)
        }
    }


    return (
        <ul 
            onClick={onClick}
            className={`
                ${className}
                text-center 
                flex justify-center items-center
                gap-2
            `}
        >
            {langArray.map((lang, index) => {
                return (
                    <li key={`header-lang-${lang}`} className='flex justify-center items-center gap-2'>
                        <p 
                            onClick={() => changeLanguage(lang)}
                            className={`uppercase cursor-pointer ${(i18n.language === lang) ? "font-dm-bold" : ""}`}
                        >
                            {LANGUAGES[lang]}
                        </p>
                        {langArray.length !== (index + 1) &&
                            <div className="h-[20px] w-[1px] bg-white" />
                        }
                    </li>
                )
            })}
        </ul>
    );
}
