//  Dependencies
// ===========================================================

import { useState } from "react"
import { withTranslation } from 'react-i18next'


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet'
import { BtnBlack } from '#components/Buttons/index.jsx'
import { LayoutService } from '#layouts'


//  Assets
// ===========================================================

import Background from '#assets/img/city-tour.png'


//  Page
// ===========================================================

function Page({ t }) {

  // Constants
  const service = "ct";

  // SEO
  const headTags = t(`${service}.headTags`, { returnObjects: true })

  // Variable
  const title = t(`${service}.title`)
  const subtitle = t(`${service}.subtitle`)
  const description = t(`${service}.description`, { returnObjects: true })
  const carousel = t(`${service}.carousel`, { returnObjects: true })

  // Hooks
  const [cityTour, setCityTour] = useState("")


  // Page
  return (
    <>
      <SEO headTags={headTags}/>
      <LayoutService
        background={Background}
        title={title}
        subtitle={subtitle}
        left={description}
        carousel={carousel}
      >
        <input id="service" name="service" type="hidden" value={service} />
        <input id="cityTour" name="cityTour" type="hidden" value={cityTour} />
        <div className="
          w-full max-w-[400px] h-fit p-6
          bg-[#E0DDDA] rounded-2xl 
          flex flex-col items-center gap-3
          font-dm-thin text-base text-black text-center
        ">
          <p className="font-dm-regular uppercase"> {t('ct.predefined.title')} </p>
          <p> {t('ct.predefined.value')} </p>
          <p className="italic"> {t('ct.predefined.price')} </p>
          <BtnBlack className="w-full" type="submit" onMouseOver={() => setCityTour("Champs Élysée - Trocadéro - Montmartre - Tour Eiffel - Arc de Triomphe")}>
            {t('ct.next1')}
          </BtnBlack>
        </div>
        <div className="
          w-full max-w-[400px] h-fit p-6
          bg-[#E0DDDA] rounded-2xl 
          flex flex-col items-center gap-3
          font-dm-thin text-base text-black text-center
        ">
          <p> {t('ct.yours.title')} </p>
          <p> {t('ct.yours.value')} </p>
          <BtnBlack className="w-full" type="submit" onMouseOver={() => setCityTour("")}>
            {t('ct.next2')}
          </BtnBlack>
        </div>
      </LayoutService>
    </>
  )
}

export default withTranslation()(Page)
