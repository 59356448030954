//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify'


//  Modules (layouts, assets, stores, components)
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'
import { IoMail } from 'react-icons/io5'
import { FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa'
import { FaFacebookF, FaInstagram } from 'react-icons/fa6'
import { Input, Select, Textarea } from '#components/Entries/index.js'


//  Functions
// ===========================================================

import useHttpsCallable from '#hooks/useHttpsCallable'


//  Components (local)
// ===========================================================

function Titles({ t }) {
  return (
    <div className="
      w-[90%] flex flex-col gap-3
      text-white text-left md:text-center
    ">
      <h2 className="font-pf-regular text-5xl md:text-6xl whitespace-pre-line md:whitespace-normal">
        {t('home.contact.title')}
      </h2>
      <h3 className="font-dm-regular text-xl md:text-2xl">
        {t('home.contact.subtitle')}
      </h3>
    </div>
  )
}

function Specific({ t, className }) {
  return (
    <div className={`
      ${className}
      w-full text-white
    `}>
      <h2 className="text-lg md:text-2xl font-dm-bold">
        {t('home.contact.left.title')}
      </h2>
      <p className="text-lg font-dm-thin">
        {t('home.contact.left.subtitle')}
      </p>
    </div>
  );
}

function ContactForm({ t, i18n }) {

  // Constants
  const services = t('global.services', { returnObjects: true })

  // Cloud functions
  const sendForm = useHttpsCallable("sendContactMail");

  // Confirm
  const sendEmail = (e) => {

    // Get data
    e.preventDefault()
    const form = new FormData(e.target)
    const formDataObj = {}
    form.forEach((value, key) => (formDataObj[key] = value))
    const getServiceName = services.find(e => e.id === formDataObj.service);
    formDataObj.service = getServiceName.value;
    toast.info(t('home.contact.form.toast.pending'))
    sendForm
      .call(formDataObj)
      .then(res => {
        if (res.rejected.length > 0)
          toast.error(t('home.contact.form.toast.error'))
        else
          toast.success(t('home.contact.form.toast.success'))
      })
  }

  // Field
  function Field({ id, placeholder, children }) {
    return (
      <div className="w-full flex flex-col">
        <label htmlFor={id} className="text-base text-left text-grey whitespace-nowrap">
          {placeholder}
        </label>
        {children}
      </div>
    )
  }

  // Form
  return (
    <form 
      onSubmit={sendEmail} 
      className="w-full h-full flex flex-col gap-8 border-l-[1px] border-[#C9C6C380] pl-6
        font-dm-light text-base text-white"
    >
      <input id="language" name="language" type="hidden" value={i18n?.language} />
      <Field id="email" placeholder={t('order.email')}>
        <Input.White 
          id="email" 
          type="email" 
          placeholder=""
          autoComplete="on"
          required 
        />
      </Field>
      <Field id="service" placeholder={t('home.contact.form.service')}>
        <Select.White
          id="service"
          values={services}
          required
        />
      </Field>
      <Field id="description" placeholder={t('home.contact.form.description')}>
        <Textarea.White
          className="w-full"
          background="dark"
          rows="3"
          id="description"
          required
        />
      </Field>
      <BtnWhite className="w-fit" type="submit" disabled={sendForm.loading}>
        {t('global.send')}
      </BtnWhite>
    </form>
  )
}

function ContactInfo({ t }) {

  // Links
  const data = [
    {
      icon: <FaMapMarkerAlt />,
      href: "http://maps.google.com/?q=6+Rue+de+la+Vache+Noire,+60440+Péroy-les-Gombries/",
      value: "6 rue de la vache noire,\n60440 Péroy-les-Gombries"
    },
    {
      icon: <IoMail />,
      href: "mailto:contact@ajaprestige.com",
      value: "contact@ajaprestige.com"
    },
    {
      icon: <FaWhatsapp />,
      href: "https://wa.me/33781171650",
      value: "+33 7 81 17 16 50"
    },
    {
      icon: <FaInstagram />,
      href: "https://www.instagram.com/aja_prestige/",
      value: "@aja_prestige"
    },
    {
      icon: <FaFacebookF />,
      href: "https://www.facebook.com/people/AJA-Prestige/61550313722465/",
      value: "Aja Prestige"
    },
  ]

  // Component
  return (
    <ul className="
      w-full flex flex-col gap-3 py-6 md:pb-0
      font-dm-light text-lg text-white
    ">
      {data.map(item =>
        <li key={`CONTACT-${item.value}`} className="flex items-center gap-3 whitespace-pre-wrap">
          <span className="text-2xl">{item.icon}</span>
          <a href={item.href} target="_blank">
            {item.value}
          </a>
        </li>
      )}
    </ul>
  )
}


//  Subpage
// ===========================================================

function Page({ t, i18n }) {

  // Section
  return (
    <section id="home-contact" className="w-full flex flex-col justify-center items-center gap-6 md:gap-12 py-[10vh]">

      <Titles t={t} />

      <div className="w-[90%] md:w-[80%] border-t-[1px] border-[#C9C6C380]" />

      <div className="
        relative w-full max-w-[1400px]
        flex justify-evenly items-center flex-col lg:flex-row-reverse
      ">

        <div className="
          w-full lg:w-1/2 flex flex-col items-center justify-center
          max-w-[800px] gap-6 px-6 py-6 lg:py-0
        ">
          <Specific t={t} className="block lg:hidden" />
          <ContactForm t={t} i18n={i18n} />
        </div>

        <div className="w-full h-[1px] bg-[#C9C6C380] lg:hidden" />

        <div className="
          w-full lg:w-1/2 flex flex-col items-center justify-center
          max-w-[500px] gap-6 px-6 py-6 lg:py-0
        ">
          <Specific t={t} className="hidden lg:block" />
          <ContactInfo t={t} />
        </div>

      </div>

    </section>
  )
}

export default withTranslation()(Page);