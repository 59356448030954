//  Dependencies
// ===========================================================

import { useEffect } from "react"
import { withTranslation } from 'react-i18next'


//  Page
// ===========================================================

function Page({ t, i18n }) {

  // Scroll to top
  useEffect(() => window.scrollTo(0, 0), [])

  // Page
  return (
    <div className="w-full flex justify-center items-center">
      <h1 className="
        font-pf-regular text-6xl text-center text-white py-[30vh]
        sm:text-8xl
      ">
        404
      </h1>
    </div>
  )
}

export default withTranslation()(Page)