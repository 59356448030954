//  Dependencies
// ===========================================================

import { useTranslation } from 'react-i18next'


//  Popup
// ===========================================================

export const PopupCancel = ({
  hide, 
  text,
  children,
}) => {

  // Hooks
  const { t } = useTranslation()

  // Hide
  if (hide)  return null

  // Show
  return (
    <div className="fixed z-[500] inset-0 bg-[#00000080] backdrop-blur flex justify-center items-center p-3">
      <div className="
        w-full max-w-[600px] bg-card rounded-xl
        flex flex-col justify-center items-center
        text-black
        p-3 sm:p-6 md:p-12
      ">
        <p className="font-pf-regular text-center px-3 text-2xl sm:text-3xl">
          {text}
        </p>
        <div className="w-full flex justify-evenly items-center pt-6 md:pt-12">
          {children}
        </div>
      </div>
    </div>
  );
};
