//  Dependencies
// ===========================================================

import { createSlice } from '@reduxjs/toolkit'


//  Slice
// ===========================================================

export const bookSlice = createSlice({
  name: 'book',
  initialState: {
    order: window.localStorage.getItem("aja-order") ? JSON.parse(window.localStorage.getItem("aja-order")) : null,
    price: null,
    stripe: null,
  },
  reducers: {
    updateOrder(state, action) {
      state.order = action.payload
      window.localStorage.setItem("aja-order", JSON.stringify(action.payload))
    },
    updatePrice(state, action) {
      state.price = action.payload
    },
    updateStripe(state, action) {
      state.stripe = action.payload
    },
  }
})

// Export actions (creators are generated for each case reducer function)
export const { 
  updateOrder,
  updatePrice,
  updateStripe,
} = bookSlice.actions

// Export reducer
export default bookSlice.reducer