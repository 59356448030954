//  Dependencies
// ===========================================================

import { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import useHttpsCallable from '#hooks/useHttpsCallable'


//  Internal Component
// ===========================================================

function Suggestions({ searchTerm, focus, onClick, placeId, placeCity }) {

    // Local
    const { t } = useTranslation();

    // Variables
    const [status, setStatus] = useState('');
    const [results, setResults] = useState([]);

    // Cloud functions
    const getPredictions = useHttpsCallable("getPredictions");   

    // Auto Complete
    useEffect(() => {
        let delayDebounceFn;
        if (searchTerm.length >= 10) {
            setStatus('isSearching');
            if (!placeId || !placeCity) {
                delayDebounceFn = setTimeout(() => {
                    console.log("AutoComplete", searchTerm);
                    getPredictions
                        .call(searchTerm.trim())
                        .then(res => {
                            setStatus('isOk');
                            setResults(res || []);
                        });
                }, 1000);
            } else {
                setStatus('isOk');
            }
        } else {
            setStatus('isWait');
        }
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);


    // Not focused
    if ( focus !== true ) {
        return null;
    }

    // Focus
    else {

        // Wait
        if ( status === 'isWait' ) {
            return null;
        }

        // Searching
        else if ( status === 'isSearching' ) {
            return (
                <div className={`
                    absolute z-[10] w-full mt-1 rounded-lg backdrop-blur-xl p-2
                    bg-[#FFFFFF] text-black overflow-hidden whitespace-normal
                `}>
                    {t('global.address.loading')}
                </div>
            );
        }

        // Show suggestion(s)
        else {
            return (
                <div className={`
                    absolute z-[10] w-full mt-1 rounded-lg backdrop-blur-xl p-2
                    bg-[#FFFFFF] text-black overflow-hidden whitespace-normal
                `}>
                    {(results.length > 0)
                        ?   results.map((item, index) => {
                                return (
                                    <button
                                        key={item?.placeId}
                                        type="button"
                                        onClick={() => onClick(item)}
                                        className="w-full text-left p-2 rounded-lg cursor-pointer hover:bg-[#00000015]"
                                    >
                                        {item?.address}
                                    </button>
                                )
                            })
                        :   t('global.address.nothing')
                    }
                </div>        
            )
        }
    }
}


//  Component
// ===========================================================

function Address({ 
    className, 
    id, 
    defaultValue,
    defaultPlace, 
    defaultCity,
    placeholder, 
    required, 
    background, 
    border, 
    text,
    ...props
}) {

    // Hooks
    const [searchTerm, setSearchTerm] = useState(defaultValue || "");
    const [placeId, setPlaceId] = useState(defaultPlace || "");
    const [placeCity, setPlaceCity] = useState(defaultCity || "");

    // Local
    const [focus, setFocus] = useState(false);
  
    // On focus
    const onFocus = e => {
        setFocus(true);
    }

    // On blur
    const onBlur = e => {
        setTimeout(() => setFocus(false), 150);
    }

    // On change
    const onChange = e => {
        setSearchTerm(e.target.value);
        setPlaceId("");
        setPlaceCity("");
    }

    // On Click
    const onClick = e => {
        setSearchTerm(e.address);
        setPlaceId(e.placeId);
        setPlaceCity(e.city);
        props?.onChange?.({
            id: id, 
            value: e.address
        })
        props?.onChange?.({
            id: `${id}Id`, 
            value: e.placeId
        })
        props?.onChange?.({
            id: `${id}City`, 
            value: e.city
        })
    }


    // Show
    return (
        <div className={`${className} relative w-full`}>
            <input 
                className="absolute z-[-1] inset-0 w-full text-[#00000000]" 
                id={`${id}Id`} 
                name={`${id}Id`} 
                value={placeId} 
                onChange={() => {}}
                required={required} 
            />
            <input 
                className="absolute z-[-1] inset-0 w-full text-[#00000000]" 
                id={`${id}City`} 
                name={`${id}City`} 
                value={placeCity} 
                onChange={() => {}}
                required={required} 
            />
            <input 
                className={`
                    w-full 
                    ${background}
                    border-b-[1px] ${border}
                    font-dm-light text-lg ${text}
                `}
                type="search"
                autoComplete="off"
                id={id}
                name={id}
                placeholder={placeholder}
                required={required}
                value={searchTerm}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <Suggestions
                focus={focus}
                searchTerm={searchTerm}
                onClick={onClick}
                placeId={placeId}
                placeCity={placeCity}
            />
        </div>
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <Address
            background="bg-transparent"
            border="border-[#000000] focus:border-[#00000080]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <Address
            background="bg-transparent"
            border="border-[#C9C6C3] focus:border-[#ffffff]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}