//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'
import { useRef, useState } from "react"
import { HeroCard } from '#components/Elements'
import { useNavigate } from "react-router-dom"


//  Components
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'
import useWindowDimensions from '#hooks/useWindowDimensions'


//  Components (local)
// ===========================================================

function HeroPopularMobile({ cards }) {

  // Hooks
  const navigate = useNavigate()
  const cardsRef = useRef(new Object())
  const [ indexShow, setIndexShox ] = useState(0)

  // On scroll
  const onScroll = () => {
      const scrollPos = document.getElementById("carousel").scrollLeft
      for (const index in cardsRef?.current) {
          const { offsetLeft, offsetWidth } = cardsRef?.current[index]
          if ((scrollPos + offsetWidth) >= offsetLeft ) {
              if ((scrollPos + offsetWidth) <= (offsetLeft + offsetWidth) ) {
                  setIndexShox(Number(index))
              }
          }
      }
  }


  return (
      <>
          <div 
              id="carousel"
              onScroll={onScroll}
              className={`
                  w-full
                  grid
                  auto-cols-max
                  grid-flow-col
                  overflow-y-hidden
                  overflow-x-scroll
                  snap-x
                  snap-mandatory
                  scrollbar-none
                  gap-10
                  px-12 py-1
              `}
          >
            {cards.map((item, index) =>
              <div 
                onClick={() => navigate('/service/pt')}
                key={`cards-${index}`}
                ref={(element) => cardsRef.current[index] = element}
                className="w-[70vw] md:w-[325px] snap-center"
              >
                <HeroCard 
                  image={`/images/popular/${index+1}.webp`} 
                  {...item} 
                />
              </div>
            )}
          </div>
          <div className="w-full flex justify-center gap-2 md:hidden">
              {cards.map((item, index) =>
                  <div 
                      key={`points-${index}`}
                      className={`
                          w-2 aspect-square rounded-full border border-white
                          ${index === indexShow ? "bg-white" : ""}
                      `}
                  />
              )}
          </div>
      </>
  )
}

function HeroPopularComputer({ cards }) {

  // Hooks
  const navigate = useNavigate()

  return (
      <div 
          className={`
              grid 
              grid-cols-2
              xl:grid-cols-3
              justify-center
              gap-10
              px-12 py-1
          `}
      >
          {cards.map((item, index) =>
              <div 
                  onClick={() => navigate('/service/pt')}
                  key={`cards-${index}`}
                  className="w-[325px] h-full"
              >
                  <HeroCard 
                      image={`/images/popular/${index+1}.webp`} 
                      {...item} 
                  />
              </div>
          )}
      </div>
  )
}


//  Component
// ===========================================================

function Page({ t }) {

  // Hooks
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  // Texts
  const title = t('home.popular.title', { returnObjects: true })
  const description = t('home.popular.description', { returnObjects: true })

  // Cards
  const cards = t('home.popular.cards', { returnObjects: true })

  return (
    <section id="home-popular" className="w-full flex flex-col justify-center items-center gap-12 text-white my-[10vh]">
      <div className="w-[85%] md:w-[85%] max-w-[900px] flex flex-col md:text-center gap-6">
        <h2 className={`
          font-pf-regular
          whitespace-pre-line md:whitespace-normal
          text-5xl md:text-6xl
        `}>
          {title[0]} <span className="font-pf-italic">{title[1]}</span>
        </h2>
        <p className="text-lg font-dm-thin">
          {t('home.popular.description')}
        </p>
        <p className="text-lg font-dm-regular uppercase">
          {t('home.popular.discover')}
        </p>
      </div>
      {(width > 768)
        ? <HeroPopularComputer cards={cards} />
        : <HeroPopularMobile cards={cards} />
      }
      <div className="w-full flex flex-col justify-center items-center gap-3">
        <p className="text-xl font-dm-thin">
          {t('home.popular.personalized')}
        </p>
        <BtnWhite onClick={() => navigate('/services')}>
          {t('home.popular.book')}
        </BtnWhite>
      </div>
    </section>
  )
}

export default withTranslation()(Page)