//  Dependencies
// ===========================================================

import { useState } from "react"


//  Component
// ===========================================================

function CheckBox({ 
    className, 
    id, 
    defaultValue, 
    label, 
    readOnly, 
    onChange,

    background, 
    border, 
    text
}) {

    // Hooks
    const [value, setValue] = useState(defaultValue || false)

    // Function
    function editValue() {
        if ( readOnly !== true ) {
            setValue(!value)
            onChange?.({ id: id, value: !value })
        }
    }

    // Show
    return (
        <div
            className={`
                ${className}
                flex flex-row items-center gap-2
                cursor-pointer
                font-dm-light text-lg ${text}
            `}
            onClick={() => editValue()}
        >
            <input id={id} name={id} type="hidden" value={value?.toString()} />
            <div className={`
                w-4 aspect-square border-[1px] p-[1px]
                ${border}
            `}>
                {(value === true) &&
                    <div className={`w-full aspect-square ${background}`} />        
                }
            </div>
            {label &&
                <label className="whitespace-nowrap select-none">
                    {label}
                </label>            
            }
        </div>
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <CheckBox
            background="bg-[#000000]"
            border="border-[#000000]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <CheckBox
            background="bg-[#C9C6C3]"
            border="border-[#C9C6C3]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}