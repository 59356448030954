//  Dependencies
// ===========================================================

import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'


//  Modules
// ===========================================================

import { Popup } from './Popup'
import { updateOrder } from '#stores/components/bookSlice'
import { BtnBlack, BtnText } from '#components/Buttons/index.jsx'


//  Popup
// ===========================================================

export const PopupOrder = () => {

  // Hooks
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Hooks
  const order = useSelector(state => state.book.order, () => true)

  // Popup
  const [isOpen, setOpen] = useState(false)

  // Order exist (show popup)
  useEffect(() => {
    if ( ['pt', 'ct', 'ot'].includes(order?.service) === true ) {
      setOpen(true)
    }
  }, [order])

  // Confirm
  function confirm() {
    navigate(`/order`)
    setOpen(false)
  }

  // Cancel
  function cancel() {
    dispatch(updateOrder({}))
    setOpen(false)
  }

  // Show
  return (
    <Popup show={isOpen} >
      <h1 className="
        font-pf-regular text-2xl text-center text-black
        sm:text-3xl
      ">
        {t('popups.order.title')}
      </h1>
      <div className="
        w-full flex flex-col items-center gap-3 pt-3
        sm:pt-6
      ">
        <BtnBlack className="w-fit" onClick={() => confirm()}>
          {t('popups.order.confirm')}
        </BtnBlack>
        <BtnText className="" onClick={() => cancel()}>
          {t('popups.order.cancel')}
        </BtnText>
      </div>
    </Popup>
  );
};
