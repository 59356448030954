//  Modules
// ===========================================================

import { Spinner } from '#components/Elements';


//  Component (base)
// ===========================================================

function Button({
    className, 
    type, 
    disabled, 
    children, 
    onClick, 
    onMouseOver, 
    background, 
    border, 
    text
}) {
    return (
        <button 
            type={type}
            disabled={disabled === true}
            onClick={(disabled === true) ? null : onClick}
            onMouseOver={(disabled === true) ? null : onMouseOver}
            className={`
                ${className}
                rounded-sm
                px-6 py-2 sm:py-1.5
                ${(disabled === true)
                    ? "border-[1px] border-[#606060] bg-[#606060] text-[#d0d0d0]"
                    : `${background} ${border} ${text}`
                }
            `}
        >
            {disabled 
                ?   <div className="w-full h-full flex items-center justify-center">
                        <Spinner className="w-8" />
                    </div>
                :   <div className="font-dm-light text-center text-base sm:text-lg">
                        {children}
                    </div>
            }
        </button>
    )
}


//  Exports
// ===========================================================

export function BtnText(props) {
    return (
        <Button
            background=""
            border=""
            text="hover:underline"
            {...props}
        />
    )
}

export function BtnBlackFilled(props) {
    return (
        <Button
            background="bg-black hover:bg-white"
            border="border-[1px] border-black"
            text="uppercase text-white hover:text-black"
            {...props}
        />
    )
}

export function BtnBlack(props) {
    return (
        <Button
            background="bg-transparent hover:bg-black"
            border="border-[1px] border-black"
            text="uppercase text-black hover:text-white"
            {...props}
        />
    )
}

export function BtnWhiteFilled(props) {
    return (
        <Button
            background="bg-white hover:bg-black"
            border="border-[1px] border-white"
            text="uppercase text-black hover:text-white"
            {...props}
        />
    )
}

export function BtnWhite(props) {
    return (
        <Button
            background="bg-transparent hover:bg-white"
            border="border-[1px] border-white"
            text="uppercase text-white hover:text-black"
            {...props}
        />
    )
}
