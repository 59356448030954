//  Dependencies
// ===========================================================

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'


//  Components
// ===========================================================

import { BtnWhite } from '#components/Buttons/index.jsx'
import { Lang, Logo } from '#components/Elements'
import useWindowDimensions from '#hooks/useWindowDimensions'


//  Assets
// ===========================================================

import { HiOutlineMenu, HiX } from 'react-icons/hi'
import { PiHouse, PiHouseFill } from 'react-icons/pi'


//  Menus
// ===========================================================

function MobileMenu({ t }) {

  // Hooks
  const navigate = useNavigate()
  const [isOpen, setOpen] = useState(false)

  // Move to section
  const handleClick = (_section) => {
    const section = document.getElementById(_section)
    if (section) {
      section?.scrollIntoView({ behavior: 'smooth' })
      setOpen(false)
    } else {
      navigate('home')
      setTimeout(() => {
        const section = document.getElementById(_section)
        section?.scrollIntoView({ behavior: 'smooth' })
        setOpen(false)
      }, 250)
    }
  }

  // Data
  const newData = [
    {
      "label": "header.home",
      "attributes": {
        "href": "/"
      }
    },
    {
      "label": "header.pt",
      "attributes": {
        "href": "/service/pt"
      }
    },
    {
      "label": "header.ot",
      "attributes": {
        "href": "/service/ot"
      }
    },
    {
      "label": "header.ct",
      "attributes": {
        "href": "/service/ct"
      }
    },
    {
      "label": "header.popular",
      "section": "home-popular",
    },
    {
      "label": "header.driver",
      "section": "home-driver"
    },
    {
      "label": "header.contact",
      "section": "home-contact"
    },
  ]

  return (
    <nav className={`
      w-full
      font-dm-thin text-lg text-white
      flex flex-col items-center
      ${isOpen ? "h-full fixed inset-0 z-[900] bg-[#000000c0] backdrop-blur-xl" : "h-fit"}
    `}>
      <div className="w-full h-[60px] flex justify-between items-center">
        <a href="/" className="h-full">
          <Logo className="h-full aspect-square p-2" />
        </a>
        <div className="h-full aspect-square flex justify-center items-center cursor-pointer">
          { isOpen
              ? <HiX
                  className='text-5xl text-white'
                  onClick={() => setOpen(false)}
                />
              : <HiOutlineMenu
                  className='text-5xl text-white'
                  onClick={() => setOpen(true)}
                />
          } 
        </div>
      </div>
      {isOpen &&
        <div className="w-full h-full flex flex-col items-center justify-between p-6">
          <nav className="w-full h-fit flex flex-col">
            <ul className="flex flex-col gap-4">
              {newData.map((link, index) => {
                return (
                  <>
                    <li
                      key={link.label} 
                      className="uppercase cursor-pointer" 
                      onClick={link?.section ? () => handleClick(link.section) : () => setOpen(false)}
                    >
                      <a {...link?.attributes}>
                        {t(link.label)}
                      </a>
                    </li>
                    <div className="w-full h-[1px] bg-[#C9C6C380]" />
                  </>
                );
              })}
            </ul>
          </nav>
          <div className="w-full flex flex-col items-center gap-6 mt-6">
            <Lang />
            <BtnWhite className="w-full max-w-[300px]" onClick={() => { navigate('/services'); setOpen(false); }}>
              {t('global.book')}
            </BtnWhite>
          </div>
        </div>      
      }
    </nav>
  )
}

function ComputerMenu({ t }) {

  // Hooks
  const navigate = useNavigate()

  // Move to section
  const handleClick = (_section) => {
    const section = document.getElementById(_section)
    if (section) {
      section?.scrollIntoView({ behavior: 'smooth' })
    } else {
      navigate('/home')
      setTimeout(() => {
        const section = document.getElementById(_section)
        section?.scrollIntoView({ behavior: 'smooth' })
      }, 250)
    }
  }


  // Navbar
  return (
    <>
      <nav className="
        w-full h-[70px] fixed z-[900]
        flex items-center
        backdrop-blur-xl bg-[#000000a0]
        font-dm-light text-sm text-white uppercase
      ">
        <ul className="w-[42%] h-full flex items-center justify-between px-8 py-2">
          <li className="flex items-center gap-6">
            <a href="/" className="group cursor-pointer">
              <PiHouse className="text-[30px] group-hover:hidden" />
              <PiHouseFill className="text-[30px] hidden group-hover:block" />
            </a>
            <Lang />
          </li>
          <li className="relative w-fit text-white uppercase cursor-pointer group">
            <span onClick={() => navigate('/services')} className="font-dm-regular text-sm">
              {t('header.services')} 
            </span>
            <ul className="
              absolute z-[10] bg-[#0B0B0B] rounded-lg
              hidden group-hover:flex 
              whitespace-nowrap p-3
              flex-col gap-1
            ">
              <li onClick={() => navigate('/service/pt')} className="font-dm-regular text-sm py-1 px-2 hover:bg-[#ffffff20] rounded">
                {t('header.pt')} 
              </li>
              <li onClick={() => navigate('/service/ot')} className="font-dm-regular text-sm py-1 px-2 hover:bg-[#ffffff20] rounded">
                {t('header.ot')} 
              </li>
              <li onClick={() => navigate('/service/ct')} className="font-dm-regular text-sm py-1 px-2 hover:bg-[#ffffff20] rounded">
                {t('header.ct')} 
              </li>
            </ul>
          </li>
          <li className="cursor-pointer relative py-1 overflow-hidden group" onClick={() => handleClick('home-popular')}>
            {t('header.popular')}
            <span className="absolute bottom-0 left-0 w-0 h-px bg-[#C9C6C3] transform-gpu group-hover:w-full transition-all duration-300"></span>
          </li>
        </ul>
        <a href="/" className="w-[16%] h-[60px] flex items-center justify-center">
          <Logo className="h-full aspect-square" />
        </a>
        <ul className="w-[42%] h-full flex items-center justify-between px-8 py-2">
          <li className="cursor-pointer relative py-1 overflow-hidden group" onClick={() => handleClick('home-driver')}>
            {t('header.driver')}
            <span className="absolute bottom-0 left-0 w-0 h-px bg-[#C9C6C3] transition-all ease-in-out transform-gpu group-hover:w-full duration-300"></span>
          </li>
          <li className="cursor-pointer relative py-1 overflow-hidden group" onClick={() => handleClick('home-contact')}>
            {t('header.contact')}
            <span className="absolute bottom-0 left-0 w-0 h-px bg-[#C9C6C3] transition-all ease-in-out transform-gpu group-hover:w-full duration-300"></span>
          </li>
          <li onClick={() => navigate('/services')}
              className={`
                  border border-[1px] border-white rounded-sm
                  px-6 py-2 sm:px-8 sm:py-1.5
                  text-white hover:bg-white hover:text-black
                  cursor-pointer
              `}
            >
              {t('global.book')}
          </li>
        </ul>
      </nav>
      <div className="h-[70px]" />
    </>
  )
}


//  Header
// ===========================================================

export function Header({}) {

  // Hooks
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  return (
    <header className="w-full h-fit">
      {(width > 1024)
        ? <ComputerMenu t={t} />
        : <MobileMenu t={t} />
      }
    </header>
  )
}
