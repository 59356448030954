//  Dependencies
// ===========================================================

import { useState, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'


//  Modules
// ===========================================================

import { Spinner } from '#components/Elements'


//  Sections
// ===========================================================

import Payment from './payment/index'
import Confirmation from '#pages/confirmation/index'


//  Constants
// ===========================================================

const appearance = {
  theme: 'stripe',
  //labels: 'floating',
  variables: {
    //fontFamily: 'DM Sans Light',
  },
  rules: {
    '.Input': {
      backgroundColor: 'transparent',
      borderRadius: 'none',
      boxShadow: 'none',
      borderTop: "0 solid #000",
      borderRight: "0 solid #000",
      borderLeft: "0 solid #000",
      borderBottom: "1px solid #000"
    },
    '.Input:focus': {
      borderColor: '#000',
      boxShadow: 'none',
      outline: '0',
    },
  }
}


//  Page
// ===========================================================

function Page() {

  // Hooks
  const [ paymentIntent ] = useOutletContext()
  const [subpage, setSubpage] = useState(0)

  // Stripe client
  const stripePromise = loadStripe(
    //"pk_test_51O71MuFfygSJXITgBmTlnUyjaGMLJfbuBCPQvU454RRthT6RJ8L8ZTcmHe6GFHOHZl6v5XgYJmSsHt593wboxIV100ixuHyccR"
    "pk_live_51O71MuFfygSJXITg3Yr2bjhJuvME0Ul1UOLkQIEURuYHeCGLQPYBRplXU2SVmAfD7pkCfDMPvAkBGeHJE9rKIKjS00HpwSdrgu"
  )

  // Go to top (subpage change)
  useEffect(() => window.scrollTo(0, 0), [subpage])

  // Content
  function Content() {
    switch (subpage) {
      case 0:
        return <Payment paymentIntent={paymentIntent} setSubpage={setSubpage} />
      case 1:
        return <Confirmation />
      default:
        return null
    }
  }

  // Pages
  if (stripePromise && paymentIntent?.client_secret) {
    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: paymentIntent.client_secret, appearance }}
      >
        <Content />
      </Elements>
    )
  }

  // Loading
  return (
    <div className="w-full min-h-[40vw] h-full flex items-center justify-center">
      <Spinner className="w-28" />
    </div>
  )
}


export default (Page)