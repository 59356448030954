//  Dependencies
// ===========================================================

import { withTranslation } from 'react-i18next'


//  Page
// ===========================================================

function Page({ t }) {
  return (
    <div className="w-full h-full flex justify-center items-center p-3 md:p-12">
      <div className="
        w-full h-full min-h-[60vh] max-w-[800px] bg-card rounded-xl
        flex flex-col justify-center items-center p-6 gap-12
        md:p-12
      ">
        <h1 className="text-4xl font-pf-regular text-center">
          {t('confirmation.thanks')}
        </h1>
        <p className="max-w-[600px] text-lg font-dm-thin text-center">
          {t('confirmation.information')}
        </p>
        <div className="w-[70px] h-[1px] bg-[#000]" />
        <p className="text-lg font-dm-regular uppercase">
          {t('confirmation.bye')}
        </p>
      </div>
    </div>
  )
}

export default withTranslation()(Page)
