//  Dependencies
// ===========================================================

import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'


//  Modules
// ===========================================================

import { updateOrder } from '#stores/components/bookSlice'
import { Carousel } from "#components/Elements"


//  Functions
// ===========================================================

import fnMinRideDuration from '#functions/fnMinRideDuration'
import fnMinStartTime from '#functions/fnMinStartTime'


//  Layout
// ===========================================================

export const LayoutService = ({ title, subtitle, background, left, children, carousel }) => {

  // Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Send form
  function handleSubmit(e) {
    e.preventDefault()
    const form = new FormData(e.target)
    const formDataObj = {}
    form.forEach((value, key) => (formDataObj[key] = value))

    // Check min start time
    if ((formDataObj.service === "ot") || (formDataObj.service === "pt")) {
      const minStartTime = fnMinStartTime(formDataObj);
      if (minStartTime === false)
        return;
    }

    // Check duration time
    if (formDataObj.service === "ot") {
      const minDuration = fnMinRideDuration(formDataObj); 
      if (minDuration === false)
        return;     
    }

    dispatch(updateOrder(formDataObj))
    navigate(`/order`)
  }


  return (
    <form onSubmit={handleSubmit} className="
      relative w-full flex flex-col items-center gap-6 overflow-hidden
      md:min-h-[95vh] md:justify-end md:gap-12 md:py-12
    ">

      <div className="
        fixed top-0 z-[-1] w-[150%] h-full flex justify-center left-[-25%]
        md:w-full md:left-0
      ">
        <img src={background} alt={title} className="absolute w-full opacity-80" />
      </div>

      <div className="h-20 md:hidden" />

      <div className="flex flex-col gap-3 text-white text-center px-3">
        <h1 className="font-pf-regular text-5xl md:text-6xl">{title}</h1>
        <h2 className="font-dm-thin text-xl uppercase">{subtitle}</h2>
      </div>

      <div className="w-full flex flex-wrap justify-center gap-3 p-3">

        <div className="
          w-full max-w-[400px] p-6
          border border-[#C9C6C3] rounded-2xl backdrop-blur 
          flex flex-col justify-center items-center gap-6
        ">
          <p className="font-dm-thin text-white text-center text-lg">
            {left?.[0]}
          </p>
          <p className="font-dm-thin text-white text-center text-lg italic">
            {left?.[1]}
          </p>
        </div>

        <div className="
          w-full max-w-[400px]
          flex flex-col gap-3
        ">
          {children}
        </div>

        <div className="
          w-full max-w-[400px] 
          border border-[#C9C6C3] rounded-2xl backdrop-blur 
        ">
          <Carousel data={(carousel || []).map(item => (
            <div className="
              w-full h-full flex flex-col justify-center items-center p-6 gap-6 overflow-hidden
              text-white text-center
            ">
              <h3 className="font-dm-regular text-xl uppercase"> {item.title} </h3>
              <p className="font-dm-thin text-lg"> {item.description} </p>
            </div>
          ))} />
        </div>

      </div>

    </form>
  )
}
