//  Dependencies
// ===========================================================

import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';


//  Slices
// ===========================================================

import bookReducer from './components/bookSlice';


//  Store
// ===========================================================

export const store = configureStore({
  reducer: 
    combineReducers({
      book: bookReducer,
    })
});
