//  Dependencies
// ===========================================================

import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';


//  Modules
// ===========================================================

import Router from '#core/Router';
import { store } from '#stores/store';


//  Services
// ===========================================================

import '#services/i18n';
import '#services/firebase';


//  Styles
// ===========================================================

import './styles.css';
import 'react-toastify/dist/ReactToastify.css';


//  Root
// ===========================================================

createRoot(document.getElementById('root'))
  .render(
    <StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <Suspense fallback="...loading">
            <Router />
            <ToastContainer
              position="bottom-left"
              autoClose={10000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover={false}
              theme="light"
              toastStyle={{ 
                backgroundColor: "#E0DDDA",
                fontFamily: "DM Sans, sans-serif",
                color: "#000"
              }}
            />
          </Suspense>
        </ReduxProvider>
      </HelmetProvider>
    </StrictMode>
  );