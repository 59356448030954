//  Component (base)
// ===========================================================

function Input({
    className,
    type, 
    placeholder, 
    id, 
    required, 
    defaultValue, 
    onChange, 
    autoComplete,

    background, 
    border, 
    text
}) {
    return (
        <input 
            className={`
                ${className}
                ${background}
                ${border}
                font-dm-light text-lg ${text}
            `}
            autoComplete={autoComplete}
            type={type}
            placeholder={placeholder}
            id={id}
            name={id}
            required={required}
            defaultValue={defaultValue}
            onChange={onChange}
        />
    )
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <Input
            background="bg-transparent"
            border="border-b-[1px] border-[#000000] focus:border-[#00000080]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <Input
            background="bg-transparent"
            border="border-b-[1px] border-[#C9C6C3] focus:border-[#ffffff]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}