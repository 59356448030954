//  Dependencies
// ===========================================================

import { useEffect } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import { useStore } from 'react-redux'
import { withTranslation } from 'react-i18next'


//  Modules
// ===========================================================

import { SEO } from '#components/Helmet'


//  Content
// ===========================================================

function SetError() {

  // Hooks
  const navigate = useNavigate()
  const store = useStore()

  // Order exist ?
  useEffect(() => {
    const book = store.getState().book
    if ( ['pt', 'ct', 'ot'].includes(book?.order?.service) === false ) {
      navigate(`/services`)
    }
  }, [])

  return (null)
}


//  Route
// ===========================================================

function Route({ t }) {

  // SEO (default)
  const headTags = t('order.headTags', { returnObjects: true })

  // Route
  return (
    <>
      <SEO headTags={headTags} />
      <main className="w-full h-full">
        <Outlet />
      </main>
      <SetError />
    </>
  )
}

export default withTranslation()(Route)