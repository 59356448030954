//  Dependencies
// ===========================================================

import { useState, useEffect } from 'react';


//  Function
// ===========================================================

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


//  Export
// ===========================================================

export default function (_width) {
  const [condition, setCondition] = useState(() => {
    const { width } = getWindowDimensions();
    if ( width <= _width ) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions();
      if ( width <= _width ) {
        setCondition(true);
      } else {
        setCondition(false);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return condition;
}
