//  Popup
// ===========================================================

export const Popup = ({ show, children }) => {

  // Hide
  if (show !== true)  return null;

  // Show
  return (
    <div className="
      fixed z-[900] inset-0 bg-[#00000020] backdrop-blur flex justify-center items-center p-3
      sm:p-6
    ">
      <div className="
        w-full max-w-[600px] bg-[#E0DDDA] rounded-xl p-3
        sm:p-6
      ">
        {children}
      </div>
    </div>
  );
};
