//  Dependecies
// ===========================================================

import { useEffect, useRef, useState, useId } from "react"
import useWindowDimensions from '#hooks/useWindowDimensions'


//  Modules
// ===========================================================

export function CarVehicles(props) {

  // Hooks
  const carouselId = useId()
  const cardsRef = useRef(new Object())
  const { width } = useWindowDimensions()
  const [ cardWidth, setCardWidth ] = useState(0)

  // Update cards width
  useEffect(() => {
    const carousel = document.getElementById(carouselId)
    setCardWidth(carousel?.offsetWidth)
  }, [width])

  // Update car show
  useEffect(() => {
    const carousel = document.getElementById(carouselId)
    carousel.scrollTo({
      left: cardsRef?.current[props.index].offsetLeft, 
      behavior: 'smooth'
    })
  }, [cardWidth, cardsRef, props.index])


  return (
    <div 
      id={carouselId}
      className={`
        relative w-full h-full
        grid auto-cols-max grid-flow-col
        overflow-y-hidden overflow-x-hidden
        snap-x snap-mandatory
        scrollbar-none
      `}
    >
      {props.data.map((children, i) =>
        <div 
          key={`carousel-cards-${i}`}
          ref={(element) => cardsRef.current[i] = element}
          style={{ width: cardWidth }}
          className="snap-center"
        >
          {children}
        </div>
      )}
    </div>
  )
}