//  Component
// ===========================================================

export function Spinner({ className }) {
    return (
        <div className={`
            ${className}
            aspect-square
            flex justify-center items-center
            relative
        `}>
            <div className="
                w-full h-full rounded-full absolute
                border-[3px] border-solid border-[#000000]"
            />
            <div className="
                w-full h-full rounded-full animate-spin absolute
                border-[3px] border-solid border-[#ffffffa0] border-t-[#00000000]"
            />
        </div>
    )
}
