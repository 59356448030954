//  Dependencies
// ===========================================================

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

//  Constants
// ===========================================================

import LANGUAGES from '#constants/languages.json';


//  Service
// ===========================================================

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language, learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next, for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Active debug message
    debug: process.env.NODE_ENV === "development",
    // Strategy to define which language codes to lookup. Example: given set language is en-US: - 'currentOnly' ⇒ 'en-US'
    load: 'languageOnly',
    // Languages available
    supportedLngs: Object.keys(LANGUAGES),
    // Override dev i18n default language
    fallbackLng: 'en',
    // Not needed for react as it escapes by default
    interpolation: {
      escapeValue: false,
    },
    // Load files
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    },
    // Custom detection from path
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'subdomain'],
      caches: ['cookie'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
  });

export default i18next;