//  Assets
// ===========================================================

import LOGO from '#assets/img/logomark-blanc.png';


//  Component
// ===========================================================

export function Logo({ style, className, onClick }) {
    return (
        <div 
            style={style}
            className={`
                ${className}
            `}
            onClick={onClick}
        >
            <img src={LOGO} alt="Logo" />
        </div>
    );
}
