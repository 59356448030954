//  Component (base)
// ===========================================================

function Textarea({
    className, 
    id, 
    rows, 
    placeholder, 
    defaultValue, 
    onChange, 
    
    background,
    border, 
    text
}) {
    
    // Textarea
    return (
        <textarea 
            rows={rows}
            className={`
                ${className}
                ${background}
                border-[1px] ${border}
                font-dm-light text-lg ${text}
                p-2
            `}
            placeholder={placeholder}
            id={id}
            name={id}
            defaultValue={defaultValue}
            onChange={onChange}
        />
    );
}


//  Exports
// ===========================================================

function Black(props) {
    return (
        <Textarea
            background="bg-transparent"
            border="border-[#000000] focus:border-[#00000080]"
            text="text-black"
            {...props}
        />
    )
}

function White(props) {
    return (
        <Textarea
            background="bg-transparent"
            border="border-[#C9C6C3] focus:border-[#ffffff]"
            text="text-white"
            {...props}
        />
    )
}

export default {
    Black: Black,
    White: White,
}